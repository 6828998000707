import Admin from "./views/Admins";
import UserProfile from "./views/AdminProfile";
import Dashboard from "./views/Dashboard";
import Login from "./views/Login";
import Organization from "./views/Organizations";
import Activities from "./views/Activities";
import Users from "./views/Users";

const unprotectedRoute = [
  {
    path: "/login",
    name: "Login",
    icon: "fa fa-sign-in",
    component: Login,
    layout: "",
  },
];

const protectedRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "",
  },
  {
    path: "/activities",
    name: "Activities",
    icon: "pe-7s-note2",
    component: Activities,
    layout: "",
  },
  {
    path: "/users",
    name: "Users",
    icon: "pe-7s-users",
    component: Users,
    layout: "",
  },
  {
    path: "/organizations",
    name: "Organizations",
    icon: "pe-7s-world",
    component: Organization,
    layout: "",
  },
  {
    path: "/admins",
    name: "Admins",
    icon: "pe-7s-door-lock",
    component: Admin,
    layout: "",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "",
  },
];

export default [unprotectedRoute, protectedRoutes];
