import { combineReducers } from "redux";
import C from "../../constants/index.constants";

const getUserDashboard = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_DASHBOARD_USER:
      return {...action.payload};

    case C.CLEAR_RESPONSE_DASHBOARD_USER:
      return {};

    default:
      return state;
  }
};

const createUser = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_CREATE_USER:
      return {...action.payload};

    case C.CLEAR_RESPONSE_CREATE_USER:
      return {};

    default:
      return state;
  }
};

const loginUser = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_LOGIN_USER:
      return {...action.payload};

    case C.CLEAR_RESPONSE_LOGIN_USER:
      return {};

    default:
      return state;
  }
};

const getAllUsers = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ALL_USERS:
      return {...action.payload};

    case C.CLEAR_RESPONSE_GET_ALL_USERS:
      return {};

    default:
      return state;
  }
};

const getUser = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_USER:
      return {...action.payload};

    case C.CLEAR_RESPONSE_GET_USER:
      return {};

    default:
      return state;
  }
};

const getUserById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_USER_BY_ID:
      return {...action.payload};

    case C.CLEAR_RESPONSE_GET_USER_BY_ID:
      return {};

    default:
      return state;
  }
};

const getUserActivities = (state = [], action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_USER_ACTIVITIES:
      return [...action.payload];

    case C.CLEAR_RESPONSE_GET_USER_ACTIVITIES:
      return [];

    default:
      return state;
  }
};

const getUserByIdActivities = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_USER_BY_ID_ACTIVITIES:
      return {...action.payload};

    case C.CLEAR_RESPONSE_GET_USER_BY_ID_ACTIVITIES:
      return {};

    default:
      return state;
  }
};

const updateUserById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_UPDATE_USER_BY_ID:
      return {...action.payload};

    case C.CLEAR_RESPONSE_UPDATE_USER_BY_ID:
      return {};

    default:
      return state;
  }
};

const updateUserPassword = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_UPDATE_USER_PASSWORD:
      return {...action.payload};

    case C.CLEAR_RESPONSE_UPDATE_USER_PASSWORD:
      return {};

    default:
      return state;
  }
};

const verifyUser = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_VERIFY_USER:
      return {...action.payload};

    case C.CLEAR_RESPONSE_VERIFY_USER:
      return {};

    default:
      return state;
  }
};

const deleteUserById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_DELETE_USER_BY_ID:
      return {...action.payload};

    case C.CLEAR_RESPONSE_DELETE_USER_BY_ID:
      return {};

    default:
      return state;
  }
};

const logoutUserById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_LOGOUT_USER_BY_ID:
      return {...action.payload};

    case C.CLEAR_RESPONSE_LOGOUT_USER_BY_ID:
      return {};

    default:
      return state;
  }
};

const response = combineReducers({
  getUserDashboard,
  createUser,
  loginUser,
  getAllUsers,
  getUser,
  getUserById,
  getUserActivities,
  getUserByIdActivities,
  updateUserById,
  updateUserPassword,
  verifyUser,
  deleteUserById,
  logoutUserById,
});

export default response;
