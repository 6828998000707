import React, { Component } from "react";
import {
  Col,
  DropdownButton,
  Grid,
  MenuItem,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  // clearGetCtmById,  clearGetOrgById,
  getActsByDate,
} from "../actions/index.actions";
import Button from "../components/CustomButton/CustomButton";
import Datepicker from "../components/Customs/Datepicker";
import ListView from "../components/Customs/ListView";
import OrganizationProfile from "./OrganizationProfile";
import UserProfile from "./UserProfile";
import refreshIcon from "../assets/img/refresh.png";
import Map from "../components/Map";

const mapStateToProps = (state, props) => {
  const { getActivitiesByDate: activities } = state.activity.response;

  return {
    props,
    idObj: props.idObj,
    activities:
      props.activities?.sort((a, b) => b.id - a.id) ??
      activities?.sort((a, b) => b.id - a.id),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetActivitiesByDate(from, to) {
    dispatch(getActsByDate(from, to));
  },
  onClearOAEACProfiles() {
    // dispatch(clearGetCtmById());
    // dispatch(clearGetOrgById());
  },
});

class UserActivities extends Component {
  componentDidMount() {
    this.props.idObj ??
      this.props.onGetActivitiesByDate(this.state.fromDate, this.state.toDate);
  }

  initialState = {
    renderProfile: {
      show: false,
      item: {},
      event: {},
      eventKey: null,
      i: null,
      type: "",
    },

    renderLocation: {
      show: false,
      item: null,
      event: null,
      eventKey: null,
      i: null,
    },

    fromDate: (new Date().setDate(1) / 1000).toFixed(0),
    toDate: (new Date().valueOf() / 1000).toFixed(0),

    page: 1,
    limit: 20,
  };

  state = {
    ...this.initialState,
  };

  customizeDate = (item, i) => {
    return (
      <td key={i}>
        {`${new Date(item.checkInTime).toDateString()} - ${new Date(
          item.checkInTime
        ).toLocaleTimeString()}`}
      </td>
    );
  };

  renderProfile = () => {
    const { renderProfile } = this.state;

    const { item, event, eventKey, i, show, type } = renderProfile;

    let profile = null;
    switch (type) {
      case "organization":
        profile = <OrganizationProfile id={item?.organizationID} notself />;
        break;

      case "user":
        profile = <UserProfile userID={item?.userID} notself />;
        break;

      default:
        break;
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          this.dismissRenderProfile();
        }}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {`${type?.toLocaleUpperCase()} PROFILE`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>{profile}</Modal.Body>

        <Modal.Footer>
          <Button onClick={this.dismissRenderProfile}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  dismissRenderProfile = () => {
    this.setState({
      renderProfile: {
        show: false,
        item: {},
        event: {},
        eventKey: null,
        i: null,
        type: "",
      },
    });

    this.props.onClearOAEACProfiles();
  };

  renderLocation = () => {
    const { renderLocation } = this.state;

    const { item, event, eventKey, i, show } = renderLocation;

    const gps = item?.gps?.split(",");

    const lat = parseFloat(gps ? gps[0] : 0);
    const lng = parseFloat(gps ? gps[1] : 0);

    return (
      <Modal
        show={show}
        onEnter={() => {
          item && (() => true)();
        }}
        onHide={() => {
          this.dismissRenderLocation();
        }}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {`Activity Location`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Map lat={lat} lng={lng} />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.dismissRenderLocation}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  dismissRenderLocation = () => {
    this.setState({
      renderLocation: {
        show: false,
      },
    });
  };

  actionItem = (item, i) => {
    const more = <Tooltip id="more_tooltip">More actions</Tooltip>;

    const moretitle = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">More</span>
      </div>
    );

    return (
      <React.Fragment key={i}>
        <td className="td-actions text-right">
          <OverlayTrigger placement="top" overlay={more}>
            <DropdownButton
              title={moretitle}
              pullRight
              noCaret
              id="dropdown-activities-more-actions"
            >
              <MenuItem
                eventKey={2.1}
                onSelect={(eventKey, event) => {
                  this.setState({
                    renderLocation: {
                      show: true,
                      item: item,
                      event,
                      eventKey,
                      i,
                    },
                  });
                }}
              >
                Location
              </MenuItem>

              <MenuItem
                disabled={!item.userID}
                eventKey={2.1}
                onSelect={(eventKey, event) => {
                  this.setState({
                    renderProfile: {
                      show: true,
                      item,
                      event,
                      eventKey,
                      i,
                      type: "user",
                    },
                  });
                }}
              >
                User
              </MenuItem>

              <MenuItem
                disabled={!item.organizationID}
                eventKey={2.4}
                onSelect={(eventKey, event) => {
                  this.setState({
                    renderProfile: {
                      show: true,
                      item,
                      event,
                      eventKey,
                      i,
                      type: "organization",
                    },
                  });
                }}
              >
                Organization
              </MenuItem>
            </DropdownButton>
          </OverlayTrigger>
        </td>
      </React.Fragment>
    );
  };

  render() {
    const {
      onGetActivitiesByDate,

      activities,
      idObj,
    } = this.props;

    let { fromDate, toDate } = this.state;

    const activityHeaders = [
      "id",
      "User ID",
      "Checkin Time",
      "User Name",
      "Organization Name",
      "GPS",
    ];

    let items = [];

    activities.map((activity, i) => {
      items = [
        ...items,
        {
          id: activity.id,
          userID: activity.userID,
          checkInTime: parseInt(activity.checkInTime),
          gps: activity.gps,
          organizationID: activity.organizationID,
          userName: activity?.user?.name,
          organizationName: activity?.organization?.name,
        },
      ];
    });

    return (
      <div className="content">
        {this.renderProfile()}
        {this.renderLocation()}

        {idObj ? null : (
          <Datepicker
            fromDate={fromDate}
            toDate={toDate}
            handleFromDate={(fromDate) => this.setState({ fromDate })}
            handleToDate={(tomDate) => this.setState({ tomDate })}
            handleClick={() => {
              this.props.onGetActivitiesByDate(fromDate, toDate);
            }}
          />
        )}

        <Grid fluid>
          <Row>
            <Col md={12}>
              {idObj ? null : (
                <div>
                  <Button
                    pullRight
                    simple
                    onClick={() => onGetActivitiesByDate(fromDate, toDate)}
                  >
                    <img
                      src={refreshIcon}
                      alt="refresh activities"
                      width="20px"
                      height="20px"
                    />
                  </Button>
                </div>
              )}
            </Col>

            <Col md={12}>
              <ListView
                title="Users Activity Logs"
                category="Activity Log"
                items={items}
                headers={activityHeaders}
                title=""
                category=""
                actionOnItemAt={[
                  {
                    index: 2,
                    action: (item, i) => this.customizeDate(item, i),
                  },
                ]}
                actionItemEnd={this.actionItem}
                selfedPage
                page={this.state.page}
                limit={this.state.limit}
                handlePageChange={(newPageNum) => {
                  this.setState({ page: newPageNum });
                }}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActivities);
