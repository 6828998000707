import { combineReducers } from "redux";
import C from "../constants/index.constants";
import activityReducer from "./activity/index.reducer";
import adminReducer from "./admin/index.reducer";
import organizationReducer from "./organization/index.reducer";
import userReducer from "./user/index.reducer";

const authentication = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_TOKEN:
      let token = action.payload;

      localStorage.setItem("rema-token", token);
      return {
        ...state,
        token,
        isAuthenticated: true,
      };

    case C.CLEAR_RESPONSE_TOKEN:
      localStorage.removeItem("rema-token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

const errors = (state = [], action) => {
  switch (action.type) {
    case C.ADD_ERROR:
      let error = action.payload;

      return [...state, { error }];

    case C.INCOMPLETE_FIELD:
      return [
        ...state,
        {
          message: `${action.payload} is required`,
          status: 2,
        },
      ];

    case C.CLEAR_ERROR:
      let errorIndex = action.payload;
      return state.filter((error, i) => i === errorIndex);

    case C.CLEAR_ALL_ERRORS:
      return [];

    default:
      return state;
  }
};

const successes = (state = [], action) => {
  switch (action.type) {
    case C.ADD_SUCCESS:
      let success = action.payload;
      return [...state, { ...success }];

    case C.CLEAR_SUCCESS:
      let successIndex = action.payload;
      return state.filter((success, i) => i === successIndex);

    case C.CLEAR_ALL_SUCCESSES:
      return [];

    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case C.FETCHING:
      return true;

    case C.FETCHING_FINISHED:
      return false;

    default:
      return state;
  }
};

const settings = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const appReducer = combineReducers({
  authentication,
  settings,
  admin: adminReducer,
  organization: organizationReducer,
  user: userReducer,
  activity: activityReducer,
  errors,
  successes,
  fetching,
});

export default appReducer;
