const AdminConstants = {
  SET_RESPONSE_DASHBOARD_ADMIN: "SET_RESPONSE_FETCHED_DASHBOARD_ADMIN",
  CLEAR_RESPONSE_DASHBOARD_ADMIN: "CLEAR_RESPONSE_FETCHED_DASHBOARD_ADMIN",

  SET_REQUEST_LOGIN_ADMIN: "SET_REQUEST_LOGIN_ADMIN",
  CLEAR_REQUEST_LOGIN_ADMIN: "CLEAR_REQUEST_LOGIN_ADMIN",
  SET_RESPONSE_LOGIN_ADMIN: "SET_RESPONSE_LOGIN_ADMIN",
  CLEAR_RESPONSE_LOGIN_ADMIN: "CLEAR_RESPONSE_LOGIN_ADMIN",

  SET_REQUEST_CREATE_ADMIN: "SET_REQUEST_CREATE_ADMIN",
  CLEAR_REQUEST_CREATE_ADMIN: "CLEAR_REQUEST_CREATE_ADMIN",
  SET_RESPONSE_CREATE_ADMIN: "SET_RESPONSE_CREATE_ADMIN",
  CLEAR_RESPONSE_CREATE_ADMIN: "CLEAR_RESPONSE_CREATE_ADMIN",

  SET_REQUEST_GET_ALL_ADMINS: "SET_REQUEST_GET_ALL_ADMINS",
  CLEAR_REQUEST_GET_ALL_ADMINS: "CLEAR_REQUEST_GET_ALL_ADMINS",
  SET_RESPONSE_GET_ALL_ADMINS: "SET_RESPONSE_GET_ALL_ADMINS",
  CLEAR_RESPONSE_GET_ALL_ADMINS: "CLEAR_RESPONSE_GET_ALL_ADMINS",

  SET_RESPONSE_GET_ADMIN: "SET_RESPONSE_GET_ADMIN",
  CLEAR_RESPONSE_GET_ADMIN: "CLEAR_RESPONSE_GET_ADMIN",

  SET_REQUEST_GET_ADMIN_BY_ID: "SET_REQUEST_GET_ADMIN_BY_ID",
  CLEAR_REQUEST_GET_ADMIN_BY_ID: "CLEAR_REQUEST_GET_ADMIN_BY_ID",
  SET_RESPONSE_GET_ADMIN_BY_ID: "SET_RESPONSE_GET_ADMIN_BY_ID",
  CLEAR_RESPONSE_GET_ADMIN_BY_ID: "CLEAR_RESPONSE_GET_ADMIN_BY_ID",

  SET_RESPONSE_GET_ADMIN_ACTIVITIES: "SET_RESPONSE_GET_ADMIN_ACTIVITIES",
  CLEAR_RESPONSE_GET_ADMIN_ACTIVITIES: "CLEAR_RESPONSE_GET_ADMIN_ACTIVITIES",

  SET_REQUEST_GET_ADMIN_BY_ID_ACTIVITIES: "SET_REQUEST_GET_ADMIN_BY_ID_ACTIVITIES",
  CLEAR_REQUEST_GET_ADMIN_BY_ID_ACTIVITIES: "CLEAR_REQUEST_GET_ADMIN_BY_ID_ACTIVITIES",
  SET_RESPONSE_GET_ADMIN_BY_ID_ACTIVITIES: "SET_RESPONSE_GET_ADMIN_BY_ID_ACTIVITIES",
  CLEAR_RESPONSE_GET_ADMIN_BY_ID_ACTIVITIES: "CLEAR_RESPONSE_GET_ADMIN_BY_ID_ACTIVITIES",


  SET_REQUEST_UPDATE_ADMIN: "SET_REQUEST_UPDATE_ADMIN",
  CLEAR_REQUEST_UPDATE_ADMIN: "CLEAR_REQUEST_UPDATE_ADMIN",
  SET_RESPONSE_UPDATE_ADMIN: "SET_RESPONSE_UPDATE_ADMIN",
  CLEAR_RESPONSE_UPDATE_ADMIN: "CLEAR_RESPONSE_UPDATE_ADMIN",

  SET_REQUEST_UPDATE_ADMIN_BY_ID: "SET_REQUEST_UPDATE_ADMIN_BY_ID",
  CLEAR_REQUEST_UPDATE_ADMIN_BY_ID: "CLEAR_REQUEST_UPDATE_ADMIN_BY_ID",
  SET_RESPONSE_UPDATE_ADMIN_BY_ID: "SET_RESPONSE_UPDATE_ADMIN_BY_ID",
  CLEAR_RESPONSE_UPDATE_ADMIN_BY_ID: "CLEAR_RESPONSE_UPDATE_ADMIN_BY_ID",

  SET_REQUEST_UPDATE_ADMIN_PASSWORD: "SET_REQUEST_UPDATE_ADMIN_PASSWORD",
  CLEAR_REQUEST_UPDATE_ADMIN_PASSWORD: "CLEAR_REQUEST_UPDATE_ADMIN_PASSWORD",
  SET_RESPONSE_UPDATE_ADMIN_PASSWORD: "SET_RESPONSE_UPDATE_ADMIN_PASSWORD",
  CLEAR_RESPONSE_UPDATE_ADMIN_PASSWORD: "CLEAR_RESPONSE_UPDATE_ADMIN_PASSWORD",

  SET_REQUEST_RESET_ADMIN_PASSWORD: "SET_REQUEST_RESET_ADMIN_PASSWORD",
  CLEAR_REQUEST_RESET_ADMIN_PASSWORD: "CLEAR_REQUEST_RESET_ADMIN_PASSWORD",
  SET_RESPONSE_RESET_ADMIN_PASSWORD: "SET_RESPONSE_RESET_ADMIN_PASSWORD",
  CLEAR_RESPONSE_RESET_ADMIN_PASSWORD: "CLEAR_RESPONSE_RESET_ADMIN_PASSWORD",

  SET_RESPONSE_DELETE_ADMIN: "SET_RESPONSE_DELETE_ADMIN",
  CLEAR_RESPONSE_DELETE_ADMIN: "CLEAR_RESPONSE_DELETE_ADMIN",

  SET_REQUEST_DELETE_ADMIN_BY_ID: "SET_REQUEST_DELETE_ADMIN_BY_ID",
  CLEAR_REQUEST_DELETE_ADMIN_BY_ID: "CLEAR_REQUEST_DELETE_ADMIN_BY_ID",
  SET_RESPONSE_DELETE_ADMIN_BY_ID: "SET_RESPONSE_DELETE_ADMIN_BY_ID",
  CLEAR_RESPONSE_DELETE_ADMIN_BY_ID: "CLEAR_RESPONSE_DELETE_ADMIN_BY_ID",

  SET_RESPONSE_LOGOUT_ADMIN: "SET_RESPONSE_LOGOUT_ADMIN",
  CLEAR_RESPONSE_LOGOUT_ADMIN: "CLEAR_RESPONSE_LOGOUT_ADMIN",

  SET_REQUEST_LOGOUT_ADMIN_BY_ID: "SET_REQUEST_LOGOUT_ADMIN_BY_ID",
  CLEAR_REQUEST_LOGOUT_ADMIN_BY_ID: "CLEAR_REQUEST_RESET_ADMIN_PASSWORD",
  SET_RESPONSE_LOGOUT_ADMIN_BY_ID: "SET_RESPONSE_LOGOUT_ADMIN_BY_ID",
  CLEAR_RESPONSE_LOGOUT_ADMIN_BY_ID: "CLEAR_RESPONSE_LOGOUT_ADMIN_BY_ID"
};

export default AdminConstants;
