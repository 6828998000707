import { baseUrl } from "../actions/fetch/base.action";

export const getCords = (items) => {
  return items?.map((item, i) => {
    const cords = (item.gps ?? "0,0").split(","),
      lat = parseFloat(cords[0]),
      lng = parseFloat(cords[1]);

    return { lat, lng };
  });
};

export const getLocation = (positionCallback, positionErrorCallback) => {
  navigator.geolocation.getCurrentPosition(
    (position) => {},
    (positionError) => {},
    { enableHighAccuracy: true }
  );
};

export const isOnLine = () => {
  return fetch(baseUrl + "/test").then((res) => {
    return res.ok && res.status == 200;
  });
};
