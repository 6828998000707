import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Button from "../components/CustomButton/CustomButton";
import UserActivities from "./UserActivities";

const mapStateToProps = (state, props) => {
  return {
    props,
    idObj: props.idObj,
    activities: props.activities?.sort((a, b) => b.id - a.id) ?? null,
  };
};

const mapDispatchToProps = (dispatch) => ({});

class Activities extends Component {
  render() {
    const { idObj, activities } = this.props;

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <UserActivities idObj={idObj} activities={activities} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
