import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getActsByDate, getDashboard } from "../actions/index.actions";
import { StatsCard } from "../components/StatsCard/StatsCard";
import Activities from "./Activities";
import Map from "../components/Map";
import { getCords } from "../shared";

const mapStateToProps = (state, props) => {
  const { getAdminDashboard: dashboard } = state.admin.response;

  const { getActivitiesByDate: activities } = state.activity.response;

  return {
    organizationCount: dashboard.organizationsCount,
    userCount: dashboard.usersCount,
    activityCount: dashboard.activitiesCount,

    organizationProps: dashboard.organizations,
    userProps: dashboard.users,

    activities,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDashboard() {
    dispatch(getDashboard());
  },
  onGetActivities(from, to) {
    dispatch(getActsByDate(from, to));
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.props.onDashboard();
  }

  createLegend(json) {
    var legend = [];

    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }

    return legend;
  }

  render() {
    const {
      activityCount,
      userCount,
      organizationCount,

      activities,
    } = this.props;

    const locations = getCords(activities),
      lat = locations[locations.length - 1]
        ? locations[locations.length - 1]["lat"]
        : 0,
      lng = locations[locations.length - 1]
        ? locations[locations.length - 1]["lng"]
        : 0;

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-briefcase text-primary" />}
                statsText="Activities"
                statsValue={activityCount}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>

            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-users text-success" />}
                statsText="Users"
                statsValue={userCount}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>

            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-world text-warning" />}
                statsText="Organizations"
                statsValue={organizationCount}
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
          </Row>

          <Row></Row>

          <Row>
            <Col md={8} style={{overflowX:"scroll"}}>
              <Activities />
            </Col>
            <Col md={4}>
              <Map lat={lat} lng={lng} locations={locations} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
