import C from "../../constants/index.constants";
import { baseurl, headers } from "../index.actions";
import { fetchApi } from "./base.action";


export const createOrganization = ({
  username,
  password,
  name,
  email,
  phone,
  address,
  gps,
  usertype,
}) => (dispatch) => {
  if (!username || !password || !name || !email || !phone || !address || !gps) {
    !username && dispatch({ type: C.INCOMPLETE_FIELD, payload: "username" });

    !password && dispatch({ type: C.INCOMPLETE_FIELD, payload: "password" });

    !name && dispatch({ type: C.INCOMPLETE_FIELD, payload: "name" });

    !email && dispatch({ type: C.INCOMPLETE_FIELD, payload: "email" });

    !phone && dispatch({ type: C.INCOMPLETE_FIELD, payload: "phone" });

    !address && dispatch({ type: C.INCOMPLETE_FIELD, payload: "address" });

    !gps && dispatch({ type: C.INCOMPLETE_FIELD, payload: "gps" });

    return;
  }

  dispatch({ type: C.FETCHING });

  return fetch(`${baseurl}/create/organization`, {
    headers: headers(),
    method: "POST",
    body: JSON.stringify({
      data: { username, password, name, email, phone, address, gps, usertype },
    }),
  })
    .then((res) => res.json())
    .then((resJson) => {
      dispatch({ type: C.FETCHING_FINISHED });

      let data = resJson.content.data;
      let error = resJson.error;
      let success = resJson.success;
      if (success.code >= 400) {
        dispatch({ type: C.ADD_ERROR, payload: error });
        return;
      }

      dispatch({
        type: C.SET_RESPONSE_CREATE_ORGANIZATION,
        payload: data,
      });
      dispatch({ type: C.ADD_SUCCESS, payload: success });

      return;
    })
    .catch((error) => {
      dispatch({ type: C.ADD_ERROR, payload: error });
      dispatch({ type: C.FETCHING_FINISHED });
      return;
    });
};

export const getOrganizationDashboard = () => {
  const url = `/get/organization/dashboard`,
    constant = C.SET_RESPONSE_DASHBOARD_ORGANIZATION;

  return fetchApi({
    url,
    method: "GET",
    constant,
  });
};

export const getAllOrganizations = (page, limit) => {
  const url =
      !limit && !page
        ? `/get/organization`
        : !limit && page
        ? `/get/organization/${page}`
        : `/get/organization/${page}/${limit}`,
    constant = C.SET_RESPONSE_GET_ALL_ORGANIZATIONS;

  return fetchApi({ url, constant, method: "GET" });
};

export const getOrganizationById = (organizationID) => {
  const url = `/get/organization/id/${organizationID}`,
    constant = C.SET_RESPONSE_GET_ORGANIZATION_BY_ID;

  return fetchApi({ url, constant, method: "GET" });
};

export const getOrganizationByIdActivities = (organizationID) => {
  const url = `/get/organization/${organizationID}/activities`,
    constant = C.SET_RESPONSE_GET_ORGANIZATION_BY_ID_ACTIVITIES;

  return fetchApi({ url, method: "GET", constant });
};

export const updateOrganizationById = ({
  id,
  username,
  name,
  email,
  phone,
  address,
  gps,
}) => (dispatch) => {
  if (!username || !name || !email || !phone || !address || !gps) {
    !username && dispatch({ type: C.INCOMPLETE_FIELD, payload: "username" });

    !name && dispatch({ type: C.INCOMPLETE_FIELD, payload: "name" });

    !email && dispatch({ type: C.INCOMPLETE_FIELD, payload: "email" });

    !phone && dispatch({ type: C.INCOMPLETE_FIELD, payload: "phone" });

    !address && dispatch({ type: C.INCOMPLETE_FIELD, payload: "address" });

    !gps && dispatch({ type: C.INCOMPLETE_FIELD, payload: "gps" });

    return;
  }

  dispatch({ type: C.FETCHING });

  return fetch(`${baseurl}/update/organization`, {
    headers: headers(),
    method: "PUT",
    body: JSON.stringify({
      data: { id, username, name, email, phone, address, gps },
    }),
  })
    .then((res) => res.json())
    .then((resJson) => {
      dispatch({ type: C.FETCHING_FINISHED });

      let data = resJson.content.data;
      let error = resJson.error;
      let success = resJson.success;
      if (success.code >= 400) {
        dispatch({ type: C.ADD_ERROR, payload: error });
        return;
      }

      dispatch({
        type: C.SET_RESPONSE_UPDATE_ORGANIZATION_BY_ID,
        payload: data,
      });
      dispatch({ type: C.ADD_SUCCESS, payload: success });

      return;
    })
    .catch((error) => {
      dispatch({ type: C.ADD_ERROR, payload: error });
      dispatch({ type: C.FETCHING_FINISHED });
      return;
    });
};

export const resetOrganizationPassword = (organizationID)  => {
  const url = `/reset/organization/password`,
    constant = C.SET_RESPONSE_RESET_ORGANIZATION_PASSWORD;

  return fetchApi({
    url,
    method: "PUT",
    constant,
    body: { id: organizationID },
  });
};

export const verifyOrganization = (organizationID)  => {
  const url = `/verify/organization`,
    constant = C.SET_RESPONSE_VERIFY_ORGANIZATION;

  return fetchApi({
    url,
    method: "PUT",
    constant,
    body: { id: organizationID },
  });
};

export const deleteOrganizationById = (organizationID)  => {
  const url = `/delete/organization/${organizationID}`,
    constant = C.SET_RESPONSE_DELETE_ORGANIZATION_BY_ID;

  return fetchApi({ url, method: "DELETE", constant });
};

export const logoutOrganizationById = (organizationID)  => {
  const url = `/logout/organization/${organizationID}`,
    constant = C.SET_RESPONSE_LOGOUT_ORGANIZATION_BY_ID;

  return fetchApi({ url, method: "POST", constant });
};
