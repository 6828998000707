

export const priviledges =  {
  PRIVILEDGE_CREATE_ADMIN: 0,
  PRIVILEDGE_GET_ANY_ADMIN: 1,
  PRIVILEDGE_UPDATE_ANY_ADMIN: 2,
  PRIVILEDGE_RESET_PASSWORDS: 3,
  PRIVILEDGE_DELETE_ANY_ADMIN: 4,
  PRIVILEDGE_LOGOUT_ANY_ADMIN: 5,

}
