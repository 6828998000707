import { combineReducers } from "redux";
import C from "../../constants/index.constants";

const root = (state = {}, action) => {
  switch (action.type) {
    case C.CHANGE_NOTIFICATION_SYSTEM:
      return { ...state, _notificationSystem: action.payload };

    case C.CHANGE_SIDEBAR_BACKGROUND_IMAGE:
      return { ...state, image: action.payload };

    case C.CHANGE_SIDEBAR_BACKGROUND_COLOR:
      return { ...state, color: action.payload };

    case C.TOGGLE_SIDEBAR_HAS_BACKGROUND_IMAGE:
      return { ...state, hasImage: action.payload };

    case C.CHANGE_FIXED_CLASSESS:
      return { ...state, fixedClasses: action.payload };

    case C.RESTORE_ROOT_DEFAULT_STATE:
      return {
        ...state,
        _notificationSystem: null,
        image: "assets/img/sidebar.jpg",
        color: "grey",
        hasImage: true,
        fixedClasses: "dropdown show-dropdown open"
      };

    default:
      return state;
  }
};

export const layout = combineReducers({
  root
});

export default layout;
