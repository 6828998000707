import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  clearGetUsrByUserId,
  getUsrById,
  getUsrByIdActivities,
  updateUsrById,
  getUsrByUserId,
} from "../actions/index.actions";
import { Card } from "../components/Card/Card.jsx";
import { FormInputs } from "../components/FormInputs/FormInputs.jsx";
import Map from "../components/Map";
import { getCords } from "../shared";
import Button from "../components/CustomButton/CustomButton";

const mapStateToProps = (state, props) => {
  const {
    getUserById,
    getUserByIdActivities: userLocations,
  } = state.user.response;

  const profile = getUserById[0] ?? getUserById;

  return {
    self: props.notself ? false : true,

    id: props.id ?? profile.id ?? "",
    name: props.name ?? profile.name ?? "",
    userID: props.userID ?? profile.userID ?? "",
    phone: props.phone ?? profile.phone ?? "",
    email: props.email ?? profile.email ?? "",
    address: props.address ?? profile.address ?? "",
    gender: props.gender ?? profile.gender ?? "",
    gps: props.gps ?? profile.gps ?? "0,0",
    image: props.image ?? profile.image ?? "",

    userLocations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onProfileUpdate(details) {},

  onSuperProfileUpdate(details) {
    dispatch(updateUsrById(details));
  },

  onSuperGetProfile(id) {
    dispatch(clearGetUsrByUserId());
    dispatch(getUsrById(id));
  },

  onGetProfileByUserId(userID) {
    dispatch(clearGetUsrByUserId());
    dispatch(getUsrByUserId(userID));
  },

  onGetUsrActivities(id) {
    dispatch(getUsrByIdActivities(id));
  },
});

class UserProfile extends Component {
  constructor(props) {
    super(props);
    if (
      this.props.self ||
      (!this.props.self && this.props.id && !this.props.name)
    ) {
      this.props.onSuperGetProfile(this.props.id);
    } else if (
      this.props.self ||
      (!this.props.self && this.props.userID && !this.props.name)
    ) {
      this.props.onGetProfileByUserId(this.props.userID);
    }
  }

  componentDidMount() {
    this.setState({
      self: this.props.self,

      id: this.props.id,
      name: this.props.name,
      userID: this.props.userID,
      phone: this.props.phone,
      email: this.props.email,
      address: this.props.address,
      gender: this.props.gender,
      gps: this.props.gps,
      image: this.props.image,
    });

    this.props.id && this.props.onGetUsrActivities(this.props.id);
  }

  componentDidUpdate = (prevProps, prevState) => {
    prevProps.id !== this.props.id && this.setState({ id: this.props.id });
    prevProps.name !== this.props.name &&
      this.setState({ name: this.props.name });
    prevProps.userID !== this.props.userID &&
      this.setState({ userID: this.props.userID });
    prevProps.phone !== this.props.phone &&
      this.setState({ phone: this.props.phone });
    prevProps.email !== this.props.email &&
      this.setState({ email: this.props.email });
    prevProps.address !== this.props.address &&
      this.setState({ address: this.props.address });
    prevProps.gender !== this.props.gender &&
      this.setState({ gender: this.props.gender });
    prevProps.gps !== this.props.gps && this.setState({ gps: this.props.gps });
    prevProps.image !== this.props.image &&
      this.setState({ image: this.props.image });
  };

  initialState = {
    id: "",
    name: "",
    userID: "",
    phone: "",
    email: "",
    address: "",
    gender: "",
    gps: "",
    image: "",
  };

  state = {
    self: true,
    ...this.initialState,
  };

  clearInputs = () => {
    this.setState({
      ...this.initialState,
    });
  };

  render() {
    const {
      onProfileUpdate,
      onSuperProfileUpdate,
      onGetUsrActivities,
      userLocations,
    } = this.props;

    const {
      self,

      id,
      name,
      userID,
      phone,
      email,
      address,
      gender,
      gps,
      image,
    } = this.state;

    const locations = getCords(userLocations?.activitys),
      cord = gps.split(","),
      lat = parseFloat(cord[0]),
      lng = parseFloat(cord[1]);

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Edit Profile"
                content={
                  <form>
                    <Col style={{}}>
                      <div
                        style={{
                          borderRadius: 100,
                          margin: "auto",
                          width: 200,
                          height: 200,
                        }}
                      >
                        <img
                          src={image}
                          className=""
                          style={{
                            minWidth: "100%",
                            minHeight: "100%",
                            background:"gray"
                          }}
                          alt="profile picture"
                        />
                      </div>
                    </Col>

                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Name",
                          // disabled: true,
                          value: name,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ name: value });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      properties={[
                        {
                          label: "Gender",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Gender",
                          // disabled: true,
                          value: gender,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ gender: value });
                          },
                        },
                        {
                          label: "User ID",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "User ID",
                          // disabled: true,
                          value: userID,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ userID: value });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-5", "col-md-7"]}
                      properties={[
                        {
                          label: "Phone Number",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Phone Number",
                          // disabled: true,
                          value: phone,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ phone: value });
                          },
                        },
                        {
                          label: "Email address",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "Email",
                          // disabled: true,
                          value: email,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ email: value });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Adress",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Home Adress",
                          // disabled: true,
                          value: address,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ address: value });
                          },
                        },
                      ]}
                    />

                    <Button
                      bsStyle="info"
                      pullRight
                      fill
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm("Are you sure you want to proceed")
                        ) {
                          self
                            ? onProfileUpdate({
                                id,
                                name,
                                userID,
                                phone,
                                email,
                                address,
                                gender,
                                gps,
                                image,
                              })
                            : onSuperProfileUpdate({
                                id,
                                name,
                                userID,
                                phone,
                                email,
                                address,
                                gender,
                                gps,
                                image,
                              });
                        }
                      }}
                    >
                      Update Profile
                    </Button>

                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Map lat={lat} lng={lng} locations={locations} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
