import React from "react";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const CustomMap = withScriptjs(
  withGoogleMap(({ lat, lng, locations, ...rest }) => {
    return (
      <GoogleMap
        defaultZoom={5}
        defaultCenter={{ lat: 7.767422, lng: 6.886879 }}
        defaultOptions={{
          scrollwheel: false,
          zoomControl: true,
          backgroundColor: "#888",
        }}
      >
        {locations.length > 0 ? (
          locations.map((position, i) => (
            <Marker
              key={i}
              position={{ lat: position.lat, lng: position.lng }}
            />
          ))
        ) : lat !== 0 && lng !== 0 ? (
          <Marker position={{ lat, lng }} />
        ) : null}
      </GoogleMap>
    );
  })
);

const Maps = ({ lat, lng, locations = [], ...rest }) => {
  return (
    <CustomMap
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC9vuBWQ74uvM8M6IO_ce8MYPippvqRdL0"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100vh` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      lat={lat}
      lng={lng}
      locations={locations}
      {...rest}
    />
  );
};

export default Maps;

// https://maps.googleapis.com/maps/api/js?key=AIzaSyC9vuBWQ74uvM8M6IO_ce8MYPippvqRdL0&v=3.exp&libraries=geometry,drawing,places
