const ActivityConstants = {
  SET_RESPONSE_GET_ALL_ACTIVITIES: "SET_RESPONSE_GET_ALL_ACTIVITIES",
  CLEAR_RESPONSE_GET_ALL_ACTIVITIES: "CLEAR_RESPONSE_GET_ALL_ACTIVITIES",

  SET_RESPONSE_GET_ACTIVITIES_BY_DATE: "SET_RESPONSE_GET_ACTIVITIES_BY_DATE",
  CLEAR_RESPONSE_GET_ACTIVITIES_BY_DATE:
    "CLEAR_RESPONSE_GET_ACTIVITIES_BY_DATE",

  SET_RESPONSE_GET_ACTIVITIES_BY_ORGANIZATION_ID:
    "SET_RESPONSE_GET_ACTIVITIES_BY_ORGANIZATION_ID",
  CLEAR_RESPONSE_GET_ACTIVITIES_BY_ORGANIZATION_ID:
    "CLEAR_RESPONSE_GET_ACTIVITIES_BY_ORGANIZATION_ID",

  SET_RESPONSE_GET_ACTIVITIES_BY_USER_ID:
    "SET_RESPONSE_GET_ACTIVITIES_BY_USER_ID",
  CLEAR_RESPONSE_GET_ACTIVITIES_BY_USER_ID:
    "CLEAR_RESPONSE_GET_ACTIVITIES_BY_USER_ID",
};

export default ActivityConstants;
