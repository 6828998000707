import response from "./response.reducer";
import { combineReducers } from "redux";
import layout from './layout.reducer';

const adminReducer = combineReducers({
  response,
  layout
});

export default adminReducer;
