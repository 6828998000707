

import response from "./response.reducer";
import { combineReducers } from "redux";


const activityReducer = combineReducers({
  response,
});

export default activityReducer;
