import C from "../constants/index.constants";
import {
  getActivitiesByDate,
  getActivitiesByOrganizationID,
  getActivitiesByUserID,
  getAllActivities
} from "./fetch/activities.action";
import {
  createAdmin,
  deleteAdminById,
  deleteSelfAdmin,
  getAdminById,
  getAdminByIdActivities,
  getAdminDashboard,
  getAllAdmins,
  getSelfAdmin,
  getSelfAdminActivities,
  loginAdmin,
  logoutAdminById,
  logoutSelfAdmin,
  resetAdminPassword,
  updateAdminById,
  updateSelfAdmin,
  updateSelfAdminPassword
} from "./fetch/admin.actions";
import {
  createOrganization,
  deleteOrganizationById,
  getAllOrganizations,
  getOrganizationById,
  getOrganizationByIdActivities,
  getOrganizationDashboard,
  logoutOrganizationById,
  resetOrganizationPassword,
  updateOrganizationById,
  verifyOrganization
} from "./fetch/organization.actions";
import {
  createUser,
  deleteUserById,
  getAllUsers,
  getUserById,
  getUserByIdActivities,

  getUserByUserId, updateUserById
} from "./fetch/user.actions";

/**
 * config
 */
//#region

export const baseurl =
  process.env.NODE_ENV === "development"
    ? "http://localhost/rema/apis/v1/admins"
    : "https://apis.touchandpay.me/rema/apis/v1/admins";

export const headers = () => ({
  Token: `Bearer ${localStorage.getItem("rema-token")}`,
  "Content-Type": "application/json",
});

//#endregion

/**
 * Admin
 */
//#region

export const saveAdmProfileDetails = (details) => (dispatch) => {
  dispatch({
    type: C.SET_REQUEST_UPDATE_ADMIN,
    payload: details,
  });
  dispatch({
    type: C.SET_RESPONSE_LOGIN_ADMIN,
    payload: details,
  });
};

export const getDashboard = () => {
  return getAdminDashboard();
};

export const createAdm = ({
  username,
  password,
  firstname,
  lastname,
  othernames,
  email,
  phone,
  address,
  priviledges,
  usertype,
}) => {
  return createAdmin({
    username,
    password,
    firstname,
    lastname,
    othernames,
    email,
    phone,
    address,
    priviledges,
    usertype,
  });
};

export const loginAdm = (username, password) => (dispatch) => {
  dispatch(loginAdmin(username, password));
  dispatch({ type: C.CLEAR_REQUEST_LOGIN_ADMIN });
};

export const getAllAdms = (page, limit) => {
  return getAllAdmins(page, limit);
};

export const getSelfAdm = () => {
  return getSelfAdmin();
};

export const getAdmById = (adminID) => {
  return getAdminById(adminID);
};

export const clearGetAdmById = () => ({
  type: C.CLEAR_RESPONSE_GET_ADMIN_BY_ID,
});

export const getSelfAdmActivities = () => {
  return getSelfAdminActivities();
};

export const getAdmByIdActivities = (adminID) => {
  return getAdminByIdActivities(adminID);
};

export const clearGetAdmByIdActivities = () => ({
  type: C.CLEAR_RESPONSE_GET_ADMIN_BY_ID_ACTIVITIES,
});

export const updateSelfAdm = ({
  id,
  username,
  firstname,
  lastname,
  othernames,
  email,
  phone,
  address,
}) => {
  return updateSelfAdmin({
    id,
    username,
    firstname,
    lastname,
    othernames,
    email,
    phone,
    address,
  });
};

export const updateAdmById = ({
  id,
  username,
  firstname,
  lastname,
  othernames,
  email,
  phone,
  address,
  priviledges,
  notself,
}) => (dispatch) => {
  if (!notself) {
    dispatch({
      type: C.SET_RESPONSE_LOGIN_ADMIN,
      payload: {
        id,
        username,
        firstname,
        lastname,
        othernames,
        email,
        phone,
        address,
        priviledges,
      },
    });
  }

  dispatch(
    updateAdminById({
      id,
      username,
      firstname,
      lastname,
      othernames,
      email,
      phone,
      address,
      priviledges,
    })
  );
};

export const updateSelfAdmPassword = ({
  adminID,
  oldPassword,
  newPassword,
  confirmPassword,
}) => (dispatch) => {
  dispatch({ type: C.CLEAR_REQUEST_UPDATE_ADMIN_PASSWORD });

  newPassword !== confirmPassword
    ? dispatch(addError({ message: "Password mismatch, please try again" }))
    : dispatch(updateSelfAdminPassword(adminID, oldPassword, confirmPassword));
};

export const resetAdmPassword = (adminID) => {
  return resetAdminPassword(adminID);
};

export const deleteSelfAdm = () => {
  return deleteSelfAdmin();
};

export const deleteAdmById = (adminID) => {
  return deleteAdminById(adminID);
};

export const logoutSelfAdm = () => (dispatch) => {
  dispatch(logoutSelfAdmin());

  dispatch({ type: C.CLEAR_RESPONSE_TOKEN });
};

export const logoutAdmById = (adminID) => {
  return logoutAdminById(adminID);
};

/**
 *
 */
//#endregion

/**
 * Organization
 */
//#region

export const getOrgDashboard = () => {
  return getOrganizationDashboard();
};

export const createOrg = ({
  username,
  password,
  name,
  email,
  phone,
  address,
  usertype,
  gps,
}) => {
  return createOrganization({
    username,
    password,
    name,
    email,
    phone,
    address,
    usertype,
    gps,
  });
};

export const getAllOrgs = (page, limit) => {
  return getAllOrganizations(page, limit);
};

export const getOrgById = (OrganizationID) => {
  return getOrganizationById(OrganizationID);
};

export const clearGetOrgById = () => ({
  type: C.CLEAR_RESPONSE_GET_ORGANIZATION_BY_ID,
});

export const getOrgByIdActivities = (organizationID) => {
  return getOrganizationByIdActivities(organizationID);
};

export const clearGetOrgByIdActivities = () => ({
  type: C.CLEAR_RESPONSE_GET_ORGANIZATION_BY_ID_ACTIVITIES,
});

export const updateOrgById = ({
  id,
  username,
  name,
  email,
  phone,
  address,
  gps,
}) => {
  return updateOrganizationById({
    id,
    username,
    name,
    email,
    phone,
    address,
    gps,
  });
};

export const resetOrgPassword = (OrganizationID) => {
  return resetOrganizationPassword(OrganizationID);
};

export const verifyOrg = (OrganizationID) => {
  return verifyOrganization(OrganizationID);
};

export const deleteOrgById = (OrganizationID) => {
  return deleteOrganizationById(OrganizationID);
};

export const logoutOrgById = (OrganizationID) => {
  return logoutOrganizationById(OrganizationID);
};

//#endregion

/**
 * User
 */
//#region

export const createUsr = ({
  name,
  email,
  phone,
  address,
  gender,
  gps,
  image,
}) => {
  return createUser({
    name,
    email,
    phone,
    address,
    gender,
    gps,
    image,
  });
};

export const getAllUsrs = (page, limit) => {
  return getAllUsers(page, limit);
};

export const getUsrById = (id) => {
  return getUserById(id);
};

export const clearGetUsrByUserId = () => ({
  type: C.CLEAR_RESPONSE_GET_USER_BY_ID,
});

export const getUsrByUserId = (userID) => {
  return getUserByUserId(userID);
};

export const getUsrByIdActivities = (id) => {
  clearGetUsrByUserIdActivities();

  return getUserByIdActivities(id);
};

export const clearGetUsrByUserIdActivities = () => ({
  type: C.CLEAR_RESPONSE_GET_USER_BY_ID_ACTIVITIES,
});

export const updateUsrById = ({
  id,
  name,
  email,
  phone,
  address,
  gender,
  gps,
  image,
}) => {
  return updateUserById({
    id,
    name,
    email,
    phone,
    address,
    gender,
    gps,
    image,
  });
};

// export const verifyUsr = (id) => {
//   return verifyUser(id);
// };

export const deleteUsrById = (id) => {
  return deleteUserById(id);
};

export const changeGetUsrPage = (page) => ({
  type: C.CHANGE_ORGANIZATION_LAYOUT_USER,
  payload: { page },
});
/**
 *
 */
//#endregion

/**
 * Activities
 */
//#region

export const getAllActs = (page, limit) => (dispatch) => {
  dispatch({ type: C.CLEAR_RESPONSE_GET_ALL_ACTIVITIES });

  dispatch(getAllActivities(page, limit));
};

export const getActsByDate = (fromDate, toDate) => (dispatch) => {
  dispatch({ type: C.CLEAR_RESPONSE_GET_ACTIVITIES_BY_DATE });

  dispatch(getActivitiesByDate(fromDate, toDate));
};

export const clearGetActsByDate = () => (dispatch) => {
  dispatch({ type: C.CLEAR_RESPONSE_GET_ACTIVITIES_BY_DATE });
};

export const getActsByUserID = (id) => (dispatch) => {
  dispatch({ type: C.CLEAR_RESPONSE_GET_ACTIVITIES_BY_USER_ID });

  dispatch(getActivitiesByUserID(id));
};

export const getActsByOrganizationID = (organizationID) => (dispatch) => {
  dispatch({ type: C.CLEAR_RESPONSE_GET_ACTIVITIES_BY_USER_ID });

  dispatch(getActivitiesByOrganizationID(organizationID));
};

/**
 *
 */
//#endregion

/**
 * Notifications
 */
//#region
/**
 * Errors
 */
export const addError = ({ message }) => ({
  type: C.ADD_ERROR,
  payload: { message },
});

export const clearError = (index) => ({
  type: C.CLEAR_ERROR,
});

export const clearAllErrors = () => ({
  type: C.CLEAR_ALL_ERRORS,
});
/**
 *
 */

/**
 * Successes
 */
export const addSuccess = ({ message }) => ({
  type: C.ADD_SUCCESS,
  payload: { message },
});

export const clearSuccess = (index) => ({
  type: C.CLEAR_SUCCESS,
});

export const clearAllSuccesses = () => ({
  type: C.CLEAR_ALL_SUCCESSES,
});
/**
 *
 */
//#endregion
