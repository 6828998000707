import { combineReducers } from "redux";
import C from "../../constants/index.constants";

const getOrganizationDashboard = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_DASHBOARD_ORGANIZATION:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_DASHBOARD_ORGANIZATION:
      return {
        agentCount: "",
        externalAgentCount: "",
        activityCount: "",
        pendingActivityCount: "",
      };

    default:
      return state;
  }
};

const createOrganization = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_CREATE_ORGANIZATION:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_CREATE_ORGANIZATION:
      return {};

    default:
      return state;
  }
};

const loginOrganization = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_LOGIN_ORGANIZATION:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_LOGIN_ORGANIZATION:
      return {};

    default:
      return state;
  }
};

const getAllOrganizations = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ALL_ORGANIZATIONS:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_GET_ALL_ORGANIZATIONS:
      return {};

    default:
      return state;
  }
};

const getOrganization = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ORGANIZATION:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_GET_ORGANIZATION:
      return {};

    default:
      return state;
  }
};

const getOrganizationById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ORGANIZATION_BY_ID:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_GET_ORGANIZATION_BY_ID:
      return {};

    default:
      return state;
  }
};

const getOrganizationActivities = (state = [], action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ORGANIZATION_ACTIVITIES:
      return [...action.payload];

    case C.CLEAR_RESPONSE_GET_ORGANIZATION_ACTIVITIES:
      return [];

    default:
      return state;
  }
};

const getOrganizationByIdActivities = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ORGANIZATION_BY_ID_ACTIVITIES:
      return {...action.payload};

    case C.CLEAR_RESPONSE_GET_ORGANIZATION_BY_ID_ACTIVITIES:
      return {};

    default:
      return state;
  }
};

const updateOrganization = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_UPDATE_ORGANIZATION:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_UPDATE_ORGANIZATION:
      return {};

    default:
      return state;
  }
};

const updateOrganizationById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_UPDATE_ORGANIZATION_BY_ID:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_UPDATE_ORGANIZATION_BY_ID:
      return {};

    default:
      return state;
  }
};

const updateOrganizationPassword = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_UPDATE_ORGANIZATION_PASSWORD:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_UPDATE_ORGANIZATION_PASSWORD:
      return {};

    default:
      return state;
  }
};

const resetOrganizationPassword = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_RESET_ORGANIZATION_PASSWORD:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_RESET_ORGANIZATION_PASSWORD:
      return {};

    default:
      return state;
  }
};

const verifyOrganization = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_VERIFY_ORGANIZATION:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_VERIFY_ORGANIZATION:
      return {};

    default:
      return state;
  }
};

const deleteOrganization = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_DELETE_ORGANIZATION:
      return {...action.payload};

    case C.CLEAR_RESPONSE_DELETE_ORGANIZATION:
      return {};

    default:
      return state;
  }
};

const deleteOrganizationById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_DELETE_ORGANIZATION_BY_ID:
      return {...action.payload};

    case C.CLEAR_RESPONSE_DELETE_ORGANIZATION_BY_ID:
      return {};

    default:
      return state;
  }
};

const logoutOrganization = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_LOGOUT_ORGANIZATION:
      return {...action.payload};

    case C.CLEAR_RESPONSE_LOGOUT_ORGANIZATION:
      return {};

    default:
      return state;
  }
};

const logoutOrganizationById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_LOGOUT_ORGANIZATION_BY_ID:
      return {...action.payload};

    case C.CLEAR_RESPONSE_LOGOUT_ORGANIZATION_BY_ID:
      return {};

    default:
      return state;
  }
};

const response = combineReducers({
  getOrganizationDashboard,
  createOrganization,
  loginOrganization,
  getAllOrganizations,
  getOrganization,
  getOrganizationById,
  getOrganizationActivities,
  getOrganizationByIdActivities,
  updateOrganization,
  updateOrganizationById,
  updateOrganizationPassword,
  resetOrganizationPassword,
  verifyOrganization,
  deleteOrganization,
  deleteOrganizationById,
  logoutOrganization,
  logoutOrganizationById,
});

export default response;
