/**
 * PRIVILEDGE_CREATE_ADMIN = 0;
 * PRIVILEDGE_GET_ANY_ADMIN = 1;
 * PRIVILEDGE_UPDATE_ANY_ADMIN = 2;
 * PRIVILEDGE_RESET_PASSWORDS = 3;
 * PRIVILEDGE_DELETE_ANY_ADMIN = 4;
 * PRIVILEDGE_LOGOUT_ANY_ADMIN = 5;
 */

import C from "../../constants/index.constants";
import { baseurl, headers } from "../index.actions";
import { fetchApi } from "./base.action";

export const createAdmin = ({
  username,
  password,
  firstname,
  lastname,
  othernames,
  email,
  phone,
  address,
  priviledges,
  usertype,
}) => (dispatch) => {
  if (
    !username ||
    !password ||
    !firstname ||
    !lastname ||
    !email ||
    !phone ||
    !address ||
    !priviledges
  ) {
    !username && dispatch({ type: C.INCOMPLETE_FIELD, payload: "username" });

    !password && dispatch({ type: C.INCOMPLETE_FIELD, payload: "password" });

    !firstname && dispatch({ type: C.INCOMPLETE_FIELD, payload: "firstname" });

    !lastname && dispatch({ type: C.INCOMPLETE_FIELD, payload: "lastname" });

    !email && dispatch({ type: C.INCOMPLETE_FIELD, payload: "email" });

    !phone && dispatch({ type: C.INCOMPLETE_FIELD, payload: "phone" });

    !address && dispatch({ type: C.INCOMPLETE_FIELD, payload: "address" });

    !priviledges &&
      dispatch({ type: C.INCOMPLETE_FIELD, payload: "priviledges" });

    return;
  }

  dispatch({ type: C.FETCHING });

  return fetch(`${baseurl}/create/admin`, {
    headers: headers(),
    method: "POST",
    body: JSON.stringify({
      data: {
        username,
        password,
        firstname,
        lastname,
        othernames,
        email,
        phone,
        address,
        priviledges,
        usertype,
      },
    }),
  })
    .then((res) => res.json())
    .then((resJson) => {
      dispatch({ type: C.FETCHING_FINISHED });

      let data = resJson.content.data;
      let error = resJson.error;
      let success = resJson.success;
      if (success.code >= 400) {
        dispatch({ type: C.ADD_ERROR, payload: error });
        return;
      }

      dispatch({
        type: C.SET_RESPONSE_CREATE_ADMIN,
        payload: data,
      });
      dispatch({ type: C.ADD_SUCCESS, payload: success });

      return;
    })
    .catch((error) => {
      dispatch({ type: C.ADD_ERROR, payload: error });
      dispatch({ type: C.FETCHING_FINISHED });
      return;
    });
};

export const loginAdmin = (username, password) => (dispatch) => {
  dispatch({ type: C.FETCHING });

  return fetch(`${baseurl}/login/admin`, {
    headers: headers(),
    method: "POST",
    body: JSON.stringify({ data: { username: username, password } }),
  })
    .then((res) => res.json())
    .then((resJson) => {
      dispatch({ type: C.FETCHING_FINISHED });

      let data = resJson.content.data;
      let error = resJson.error;
      let success = resJson.success;

      if (success.code >= 400) {
        dispatch({ type: C.ADD_ERROR, payload: error });
        return;
      }

      let token = resJson.token;

      dispatch({ type: C.SET_RESPONSE_TOKEN, payload: token });
      dispatch({ type: C.SET_RESPONSE_LOGIN_ADMIN, payload: data });
      dispatch({ type: C.ADD_SUCCESS, payload: success });
      return;
    })
    .catch((error) => {
      dispatch({ type: C.ADD_ERROR, payload: error });

      dispatch({ type: C.FETCHING_FINISHED });
      return;
    });
};

export const getAdminDashboard = () => {
  const url = "/get/admin/dashboard",
    constant = C.SET_RESPONSE_DASHBOARD_ADMIN,
    method = "GET";

  return fetchApi({ url, constant, method });
};

export const getAllAdmins = (page, limit) => {
  const url =
      !limit && !page
        ? `/get/admins`
        : !limit && page
        ? `/get/admins/${page}`
        : `/get/admins/${page}/${limit}`,
    constant = C.SET_RESPONSE_GET_ALL_ADMINS,
    method = "GET";

  return fetchApi({ url, constant, method });
};

export const getSelfAdmin = () => {
  const url = `/get/admin`,
    constant = C.SET_RESPONSE_GET_ADMIN,
    method = "GET";

  return fetchApi({ url, constant, method });
};

export const getAdminById = (adminID) => {
  const url = `/get/admin/${adminID}`,
    constant = C.SET_RESPONSE_GET_ADMIN_BY_ID,
    method = "GET";

  return fetchApi({ url, constant, method });
};

export const getSelfAdminActivities = () => {
  const url = `/get/admin/activities`,
    constant = C.SET_RESPONSE_GET_ADMIN_ACTIVITIES,
    method = "GET";

  return fetchApi({ url, constant, method });
};

export const getAdminByIdActivities = (adminID) => {
  const url = `/get/admin/${adminID}/activities`,
    constant = C.SET_RESPONSE_GET_ADMIN_BY_ID_ACTIVITIES,
    method = "GET";

  return fetchApi({ url, constant, method });
};

export const updateSelfAdmin = ({
  id,
  username,
  firstname,
  lastname,
  othernames,
  email,
  phone,
  address,
}) => (dispatch) => {
  if (!username || !firstname || !lastname || !email || !phone || !address) {
    !username && dispatch({ type: C.INCOMPLETE_FIELD, payload: "username" });

    !firstname && dispatch({ type: C.INCOMPLETE_FIELD, payload: "firstname" });

    !lastname && dispatch({ type: C.INCOMPLETE_FIELD, payload: "lastname" });

    !email && dispatch({ type: C.INCOMPLETE_FIELD, payload: "email" });

    !phone && dispatch({ type: C.INCOMPLETE_FIELD, payload: "phone" });

    !address && dispatch({ type: C.INCOMPLETE_FIELD, payload: "address" });

    return;
  }

  dispatch({ type: C.FETCHING });

  return fetch(`${baseurl}/update/admin`, {
    headers: headers(),
    method: "PUT",
    body: JSON.stringify({
      data: {
        id,
        username,
        firstname,
        lastname,
        othernames,
        email,
        phone,
        address,
      },
    }),
  })
    .then((res) => res.json())
    .then((resJson) => {
      dispatch({ type: C.FETCHING_FINISHED });

      let data = resJson.content.data;
      let error = resJson.error;
      let success = resJson.success;
      if (success.code >= 400) {
        dispatch({ type: C.ADD_ERROR, payload: error });
        return;
      }

      dispatch({ type: C.ADD_SUCCESS, payload: success });
      dispatch({ type: C.SET_RESPONSE_UPDATE_ADMIN, payload: data });
      return;
    })
    .catch((error) => {
      dispatch({ type: C.ADD_ERROR, payload: error });
      dispatch({ type: C.FETCHING_FINISHED });
      return;
    });
};

export const updateAdminById = ({
  id,
  username,
  firstname,
  lastname,
  othernames,
  email,
  phone,
  address,
  priviledges,
  usertype,
}) => (dispatch) => {
  if (
    !username ||
    !firstname ||
    !lastname ||
    !email ||
    !phone ||
    !address ||
    !priviledges
  ) {
    !username && dispatch({ type: C.INCOMPLETE_FIELD, payload: "username" });

    !firstname && dispatch({ type: C.INCOMPLETE_FIELD, payload: "firstname" });

    !lastname && dispatch({ type: C.INCOMPLETE_FIELD, payload: "lastname" });

    !email && dispatch({ type: C.INCOMPLETE_FIELD, payload: "email" });

    !phone && dispatch({ type: C.INCOMPLETE_FIELD, payload: "phone" });

    !address && dispatch({ type: C.INCOMPLETE_FIELD, payload: "address" });

    !priviledges &&
      dispatch({ type: C.INCOMPLETE_FIELD, payload: "priviledges" });

    return;
  }

  dispatch({ type: C.FETCHING });

  return fetch(`${baseurl}/update/admin/any`, {
    headers: headers(),
    method: "PUT",
    body: JSON.stringify({
      data: {
        id,
        username,
        firstname,
        lastname,
        othernames,
        email,
        phone,
        address,
        priviledges,
        usertype,
      },
    }),
  })
    .then((res) => res.json())
    .then((resJson) => {
      dispatch({ type: C.FETCHING_FINISHED });

      let data = resJson.content.data;
      let error = resJson.error;
      let success = resJson.success;
      if (success.code >= 400) {
        dispatch({ type: C.ADD_ERROR, payload: error });
        return;
      }

      dispatch({
        type: C.SET_RESPONSE_UPDATE_ADMIN_BY_ID,
        payload: data,
      });
      dispatch({ type: C.ADD_SUCCESS, payload: success });

      return;
    })
    .catch((error) => {
      dispatch({ type: C.ADD_ERROR, payload: error });
      dispatch({ type: C.FETCHING_FINISHED });
      return;
    });
};

export const updateSelfAdminPassword = (adminID, oldPassword, newPassword) => {
  const url = `/update/admin/password`,
    constant = C.SET_RESPONSE_UPDATE_ADMIN_PASSWORD,
    method = "PUT",
    body = { id: adminID, oldPassword, newPassword };

  return fetchApi({ url, constant, method, body });
};

export const resetAdminPassword = (adminID) => {
  const url = `/reset/admin/password`,
    constant = C.SET_RESPONSE_RESET_ADMIN_PASSWORD,
    method = "PUT",
    body = { id: adminID };

  return fetchApi({ url, constant, method, body });
};

export const deleteSelfAdmin = () => {
  const url = `/delete/admin`,
    constant = C.SET_RESPONSE_DELETE_ADMIN,
    method = "DELETE";

  return fetchApi({ url, constant, method });
};

export const deleteAdminById = (adminID) => {
  const url = `/delete/admin/${adminID}`,
    constant = C.SET_RESPONSE_DELETE_ADMIN_BY_ID,
    method = "DELETE";

  return fetchApi({ url, constant, method,  });
};

export const logoutSelfAdmin = () => {
  const url = `/logout/admin`,
    constant = C.SET_RESPONSE_LOGOUT_ADMIN,
    method = "POST";

  return fetchApi({ url, constant, method });
};

export const logoutAdminById = (adminID) => {
  const url = `/logout/admin/${adminID}`,
    constant = C.SET_RESPONSE_LOGOUT_ADMIN_BY_ID,
    method = "POST";

  return fetchApi({ url, constant, method, });
};

