import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CircularJSON from "circular-json";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { addError } from "./actions/index.actions";
import "./assets/css/animate.min.css";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import AdminLayout from "./layouts/AdminLayout.js";
import storeFactory from "./store";
import sampleData from "./store/initstate.json";

const initialState = localStorage["redux-store"]
  ? CircularJSON.parse(localStorage["redux-store"])
  : sampleData;

const saveState = () =>
  (localStorage["redux-store"] = CircularJSON.stringify(store.getState()));

const handleError = (error, errors, errorses) => {
  return store.dispatch(addError({ message: error }));
};

const store = storeFactory(initialState);
// store.subscribe(saveState);

window.React = React;
window.store = store;

window.addEventListener("error", handleError);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route render={(props) => <AdminLayout {...props} />} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
