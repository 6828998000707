const OrganizationConstants = {

    CHANGE_ORGANIZATION_LAYOUT_USER: "CHANGE_ORGANIZATION_LAYOUT_USER",

    SET_RESPONSE_DASHBOARD_ORGANIZATION: "SET_RESPONSE_FETCHED_DASHBOARD_ORGANIZATION",
    CLEAR_RESPONSE_DASHBOARD_ORGANIZATION: "CLEAR_RESPONSE_FETCHED_DASHBOARD_ORGANIZATION",

    SET_RESPONSE_LOGIN_ORGANIZATION: "SET_RESPONSE_LOGIN_ORGANIZATION",
    CLEAR_RESPONSE_LOGIN_ORGANIZATION: "CLEAR_RESPONSE_LOGIN_ORGANIZATION",

    SET_RESPONSE_CREATE_ORGANIZATION: "SET_RESPONSE_CREATE_ORGANIZATION",
    CLEAR_RESPONSE_CREATE_ORGANIZATION: "CLEAR_RESPONSE_CREATE_ORGANIZATION",

    SET_RESPONSE_GET_ORGANIZATION: "SET_RESPONSE_GET_ORGANIZATION",
    CLEAR_RESPONSE_GET_ORGANIZATION: "CLEAR_RESPONSE_GET_ORGANIZATION",

    SET_RESPONSE_GET_ALL_ORGANIZATIONS: "SET_RESPONSE_GET_ALL_ORGANIZATIONS",
    CLEAR_RESPONSE_GET_ALL_ORGANIZATIONS: "CLEAR_RESPONSE_GET_ALL_ORGANIZATIONS",

    SET_RESPONSE_GET_ORGANIZATION_BY_ID: "SET_RESPONSE_GET_ORGANIZATION_BY_ID",
    CLEAR_RESPONSE_GET_ORGANIZATION_BY_ID:
        "CLEAR_RESPONSE_GET_ORGANIZATION_BY_ID",

    SET_RESPONSE_GET_ORGANIZATION_ACTIVITIES:
        "SET_RESPONSE_GET_ORGANIZATION_ACTIVITIES",
    CLEAR_RESPONSE_GET_ORGANIZATION_ACTIVITIES:
        "CLEAR_RESPONSE_GET_ORGANIZATION_ACTIVITIES",

    SET_RESPONSE_GET_ORGANIZATION_BY_ID_ACTIVITIES:
        "SET_RESPONSE_GET_ORGANIZATION_BY_ID_ACTIVITIES",
    CLEAR_RESPONSE_GET_ORGANIZATION_BY_ID_ACTIVITIES:
        "CLEAR_RESPONSE_GET_ORGANIZATION_BY_ID_ACTIVITIES",

    SET_RESPONSE_UPDATE_ORGANIZATION: "SET_RESPONSE_UPDATE_ORGANIZATION",
    CLEAR_RESPONSE_UPDATE_ORGANIZATION: "CLEAR_RESPONSE_UPDATE_ORGANIZATION",

    SET_RESPONSE_UPDATE_ORGANIZATION_BY_ID:
        "SET_RESPONSE_UPDATE_ORGANIZATION_BY_ID",
    CLEAR_RESPONSE_UPDATE_ORGANIZATION_BY_ID:
        "CLEAR_RESPONSE_UPDATE_ORGANIZATION_BY_ID",

    SET_RESPONSE_UPDATE_ORGANIZATION_PASSWORD:
        "SET_RESPONSE_UPDATE_ORGANIZATION_PASSWORD",
    CLEAR_RESPONSE_UPDATE_ORGANIZATION_PASSWORD:
        "CLEAR_RESPONSE_UPDATE_ORGANIZATION_PASSWORD",

    SET_RESPONSE_RESET_ORGANIZATION_PASSWORD:
        "SET_RESPONSE_RESET_ORGANIZATION_PASSWORD",
    CLEAR_RESPONSE_RESET_ORGANIZATION_PASSWORD:
        "CLEAR_RESPONSE_RESET_ORGANIZATION_PASSWORD",

    SET_RESPONSE_VERIFY_ORGANIZATION: "SET_RESPONSE_VERIFY_ORGANIZATION",
    CLEAR_RESPONSE_VERIFY_ORGANIZATION: "CLEAR_RESPONSE_VERIFY_ORGANIZATION",

    SET_RESPONSE_DELETE_ORGANIZATION: "SET_RESPONSE_DELETE_ORGANIZATION",
    CLEAR_RESPONSE_DELETE_ORGANIZATION: "CLEAR_RESPONSE_DELETE_ORGANIZATION",

    SET_RESPONSE_DELETE_ORGANIZATION_BY_ID:
        "SET_RESPONSE_DELETE_ORGANIZATION_BY_ID",
    CLEAR_RESPONSE_DELETE_ORGANIZATION_BY_ID:
        "CLEAR_RESPONSE_DELETE_ORGANIZATION_BY_ID",

    SET_RESPONSE_LOGOUT_ORGANIZATION: "SET_RESPONSE_LOGOUT_ORGANIZATION",
    CLEAR_RESPONSE_LOGOUT_ORGANIZATION: "CLEAR_RESPONSE_LOGOUT_ORGANIZATION",

    SET_RESPONSE_LOGOUT_ORGANIZATION_BY_ID:
        "SET_RESPONSE_LOGOUT_ORGANIZATION_BY_ID",
    CLEAR_RESPONSE_LOGOUT_ORGANIZATION_BY_ID:
        "CLEAR_RESPONSE_LOGOUT_ORGANIZATION_BY_ID"
};

export default OrganizationConstants;
