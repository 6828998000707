import React, { Component } from "react";
import {
  Col,
  DropdownButton,
  Grid,
  MenuItem,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  clearGetActsByDate,
  clearGetOrgByIdActivities,
  createOrg,
  deleteOrgById,
  getAllOrgs,
  getOrgById,
  getOrgByIdActivities,
  logoutOrgById,
  resetOrgPassword,
  updateOrgById,
  verifyOrg,
} from "../actions/index.actions";
import Card from "../components/Card/Card";
import Button from "../components/CustomButton/CustomButton";
import ListView from "../components/Customs/ListView";
import FormInputs from "../components/FormInputs/FormInputs";
import Map from "../components/Map";
import Activities from "./UserActivities";

const mapStateToProps = (state, props) => {
  const {
    getAllOrganizations: allOrgs,
    getOrganizationByIdActivities: orgByIdActivities,
  } = state.organization.response;

  return {
    props,
    allOrgs: allOrgs.all ?? [],
    allOrgsTotal: allOrgs.total,
    orgByIdActivities: orgByIdActivities.activitys,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateOrganization(details) {
    dispatch(createOrg(details));
  },
  onGetAllOrganizations(page, limit) {
    dispatch(getAllOrgs(page, limit));
  },
  onGetOrganization(organizationID) {
    dispatch(getOrgById(organizationID));
  },
  onGetOrganizationActivities(organizationID) {
    dispatch(getOrgByIdActivities(organizationID));
    dispatch(clearGetActsByDate());
  },

  onClearOrganizationActivities() {
    dispatch(clearGetOrgByIdActivities());
  },
  onUpdateOrganization(details) {
    dispatch(updateOrgById(details));
  },
  onVerifyOrganization(organizationID) {
    dispatch(verifyOrg(organizationID));
  },
  onResetOrganizationPassword(organizationID) {
    dispatch(resetOrgPassword(organizationID));
  },
  onDeleteOrganization(organizationID) {
    dispatch(deleteOrgById(organizationID));
  },
  onLogoutOrganization(organizationID) {
    dispatch(logoutOrgById(organizationID));
  },
});

class Organization extends Component {
  _MODE_CREATE = "Create";
  _MODE_UPDATE = "Update";

  constructor(props) {
    super(props);

    this.props.onGetAllOrganizations(this.state.page, this.state.limit);
  }

  initialState = {
    tab: "explore",

    id: "",
    name: "",
    username: "",
    password: "",
    email: "",
    phone: "",
    address: "",
    usertype: 4,
    gps: "",

    page: 1,
    limit: 20,

    mode: this._MODE_CREATE,

    renderActivities: {
      show: false,
      item: null,
      event: null,
      eventKey: null,
      i: null,
    },

    renderLocation: {
      show: false,
      item: null,
      event: null,
      eventKey: null,
      i: null,
    },
  };

  state = {
    ...this.initialState,
  };

  clearInputs = () => {
    this.setState({
      ...this.initialState,
    });
  };

  renderActivities = () => {
    const { renderActivities } = this.state;

    const { item, event, eventKey, i, show } = renderActivities;

    const { orgByIdActivities } = this.props;

    return (
      <Modal
        show={show}
        onEnter={() => {
          item && this.props.onGetOrganizationActivities(item.id);
        }}
        onHide={() => {
          this.dismissRenderActivites();
        }}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {`${item?.name} Activities`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Activities
            activities={orgByIdActivities}
            idObj={{ organizationID: item?.id }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.dismissRenderActivites}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  dismissRenderActivites = () => {
    this.setState({
      renderActivities: {
        show: false,
      },
    });

    this.props.onClearOrganizationActivities();
  };

  renderLocation = () => {
    const { renderLocation } = this.state;

    const { item, event, eventKey, i, show } = renderLocation;
    const gps = item?.gps?.split(",");

    const lat = parseFloat(gps ? gps[0] : 0);
    const lng = parseFloat(gps ? gps[1] : 0);

    return (
      <Modal
        show={show}
        onEnter={() => {
          item && (() => true)();
        }}
        onHide={() => {
          this.dismissRenderLocation();
        }}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {`${item?.name} Location`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Map lat={lat} lng={lng} />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.dismissRenderLocation}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  dismissRenderLocation = () => {
    this.setState({
      renderLocation: {
        show: false,
      },
    });

    this.props.onClearOrganizationActivities();
  };

  actionItem = (item, i) => {
    const edit = <Tooltip id="edit_tooltip">Edit Organization</Tooltip>;

    const remove = <Tooltip id="remove_tooltip">Delete Organization</Tooltip>;

    const more = <Tooltip id="more_tooltip">More actions</Tooltip>;

    const moretitle = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">More</span>
      </div>
    );

    return (
      <React.Fragment key={i}>
        <td className="td-actions text-right">
          <OverlayTrigger placement="top" overlay={edit}>
            <Button
              bsStyle="info"
              simple
              type="button"
              bsSize="xs"
              onClick={() => {
                this.setState({ ...item, mode: "Update" });
                window.scrollTo(0, 100);
              }}
              href="#edit"
            >
              <i className="fa fa-edit" />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={remove}>
            <Button
              bsStyle="danger"
              simple
              type="button"
              bsSize="xs"
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this organization ?"
                  )
                ) {
                  this.props.onDeleteOrganization(item.id);
                }
              }}
            >
              <i className="fa fa-times" />
            </Button>
          </OverlayTrigger>
        </td>

        <td className="td-actions text-right">
          <OverlayTrigger placement="top" overlay={more}>
            <DropdownButton
              title={moretitle}
              pullRight
              noCaret
              id="dropdown-organization-more-actions"
            >
              <MenuItem
                eventKey={2.1}
                onSelect={(eventKey, event) => {
                  this.setState({
                    renderLocation: {
                      show: true,
                      item: item,
                      event,
                      eventKey,
                      i,
                    },
                  });
                }}
              >
                Location
              </MenuItem>

              <MenuItem
                eventKey={2.2}
                onSelect={(eventKey, event) => {
                  this.setState({
                    renderActivities: {
                      show: true,
                      item: item,
                      event,
                      eventKey,
                      i,
                    },
                  });
                }}
              >
                Activities
              </MenuItem>

              <MenuItem
                eventKey={2.5}
                onSelect={(eventKey, event) => {
                  if (
                    window.confirm(
                      "Are you sure you want to verify this organization ?"
                    )
                  ) {
                    this.props.onVerifyOrganization(item.id);
                  }
                }}
              >
                Verify
              </MenuItem>

              <MenuItem
                eventKey={2.6}
                onSelect={(eventKey, event) => {
                  if (
                    window.confirm(
                      "Are you sure you want to reset this organization's password ?"
                    )
                  ) {
                    this.props.onResetOrganizationPassword(item.id);
                  }
                }}
              >
                Reset Password
              </MenuItem>

              <MenuItem
                eventKey={2.7}
                onSelect={(eventKey, event) => {
                  if (
                    window.confirm(
                      "Are you sure you want to logout this organization ?"
                    )
                  ) {
                    this.props.onLogoutOrganization(item.id);
                  }
                }}
              >
                Logout
              </MenuItem>
            </DropdownButton>
          </OverlayTrigger>
        </td>
      </React.Fragment>
    );
  };

  tabSwitcher = (tab) => {
    this.setState({ tab });
  };

  render() {
    const {
      onCreateOrganization,
      onGetAllOrganizations,
      onGetOrganization,
      onGetOrganizationById,
      onUpdateOrganization,
      onChangeOrganizationPage,

      allOrgs,
      adminID,
      allOrgsTotal,
    } = this.props;

    const headers = [
      "Organization Name",
      "Username",
      "Phone",
      "Email",
      "Address",
    ];

    let items = [];
    allOrgs.forEach((element) => {
      items = [
        ...items,
        {
          id: element.id,
          name: element.name,
          username: element.username,
          phone: element.phone,
          email: element.email,
          address: element.address,
          gps: element.gps,
        },
      ];
    });

    const {
      tab,

      id,
      name,
      username,
      password,
      email,
      phone,
      address,
      gps,
      usertype,

      mode,
    } = this.state;

    return (
      <div className="content">
        {this.renderActivities()}
        {this.renderLocation()}

        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={`${mode} an Organization`}
                content={
                  <form>
                    <FormInputs
                      ncols={["col-md-8", "col-md-4"]}
                      properties={[
                        {
                          label: "Organization Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Organization Name",
                          value: name,
                          onChange: (e) => {
                            let name = e.currentTarget.value;
                            this.setState({ name });
                          },
                        },
                        {
                          label: "GPS",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "GPS",
                          value: gps,
                          onChange: (e) => {
                            let gps = e.currentTarget.value;
                            this.setState({ gps });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "Username",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Username",
                          value: username,
                          onChange: (e) => {
                            let username = e.currentTarget.value;
                            this.setState({ username });
                          },
                        },
                        {
                          label: "Password",
                          type: "text",
                          disabled: mode === this._MODE_UPDATE,
                          bsClass: "form-control",
                          placeholder: "Password",
                          value: password,
                          onChange: (e) => {
                            let password = e.currentTarget.value;
                            this.setState({ password });
                          },
                        },
                        {
                          label: "Phone Number",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Phone Number",
                          value: phone,
                          onChange: (e) => {
                            let phone = e.currentTarget.value;
                            this.setState({ phone });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-4", "col-md-8"]}
                      properties={[
                        {
                          label: "Email",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: email,
                          onChange: (e) => {
                            let email = e.currentTarget.value;
                            this.setState({ email });
                          },
                        },
                        {
                          label: "Address",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Address",
                          value: address,
                          onChange: (e) => {
                            let address = e.currentTarget.value;
                            this.setState({ address });
                          },
                        },
                      ]}
                    />

                    <Button
                      bsStyle="default"
                      fill
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();

                        this.clearInputs();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      bsStyle="primary"
                      fill
                      pullRight
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm("Are you sure you want to proceed")
                        ) {
                          if (mode === this._MODE_CREATE) {
                            onCreateOrganization({
                              username,
                              password,
                              name,
                              email,
                              phone,
                              address,
                              usertype,
                              gps,
                            });
                          } else if (mode === this._MODE_UPDATE) {
                            onUpdateOrganization({
                              id,
                              username,
                              name,
                              email,
                              phone,
                              address,
                              gps,
                            });
                          }

                          this.clearInputs();
                        }
                      }}
                    >
                      {mode} Organization
                    </Button>

                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>

        <div style={{ display: tab === "explore" ? "block" : "none" }}>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <div>
                  <Button
                    pullRight
                    simple
                    onClick={() =>
                      onGetAllOrganizations(this.state.page, this.state.limit)
                    }
                  >
                    <img
                      src="/images/refresh.png"
                      alt="refresh organizations"
                      width="20"
                      height="20"
                    />
                  </Button>
                </div>

                <ListView
                  items={items}
                  headers={headers}
                  title="Organization Details"
                  category="Organizations"
                  actionItemEnd={this.actionItem}
                  controlledPage
                  page={this.state.page}
                  limit={this.state.limit}
                  handlePageChange={(newPageNum) => {
                    this.setState({ page: newPageNum });
                    onGetAllOrganizations(newPageNum, this.state.limit);
                  }}
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
