import thunk from "redux-thunk";
import { consoleReduxState } from "./consoleReduxState";
import { redirectLogin } from "./redirect";

export const Middlewares = [
  thunk,
  // consoleReduxState,
  redirectLogin
];

export default Middlewares;
