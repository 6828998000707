import React, { Component } from "react";
import {
  Col,
  DropdownButton,
  Grid,
  MenuItem,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  clearGetAdmById,
  clearGetAdmByIdActivities,
  createAdm,
  deleteAdmById,
  getAdmById,
  getAdmByIdActivities,
  getAllAdms,
  getSelfAdm,
  logoutAdmById,
  resetAdmPassword,
  updateAdmById,
} from "../actions/index.actions";
import Card from "../components/Card/Card";
import Button from "../components/CustomButton/CustomButton";
import ListView from "../components/Customs/ListView";
import FormInputs from "../components/FormInputs/FormInputs";
import { priviledges as priv } from "../variables/AppVariables";
import AdminProfile from "./AdminProfile";
import PointActivities from "./UserActivities";

const mapStateToProps = (state, props) => {
  const { loginAdmin: login } = state.admin.response;

  const {
    getAllAdmins: allAdms,
    getAdminByIdActivities: AdmByIdActivities,
  } = state.admin.response;

  return {
    props,
    allAdms:allAdms.all,
    totalAdms:allAdms.total,
    adminID: login.id,
    userPriviledges: !Array.isArray(login.priviledges)
      ? JSON.parse(login.priviledges)
      : login.priviledges,
    AdmByIdActivities,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateAdmin(details) {
    dispatch(createAdm(details));
  },
  onGetAllAdmins(page, limit) {
    dispatch(getAllAdms(page, limit));
  },
  onGetAdmin() {
    dispatch(getSelfAdm());
  },
  onGetAdminById(adminID) {
    dispatch(getAdmById(adminID));
  },
  onClearAdminById() {
    dispatch(clearGetAdmById());
  },
  onGetAdminActivities(adminID) {
    dispatch(getAdmByIdActivities(adminID));
  },
  onClearAdminActivities() {
    dispatch(clearGetAdmByIdActivities());
  },
  onUpdateAdmin(details) {
    dispatch(updateAdmById(details));
  },
  onResetAdminPassword(adminID) {
    dispatch(resetAdmPassword(adminID));
  },
  onDeleteAdmin(adminID) {
    dispatch(deleteAdmById(adminID));
  },
  onLogoutAdmin(adminID) {
    dispatch(logoutAdmById(adminID));
  },
});

class Admin extends Component {
  _MODE_CREATE = "Create";
  _MODE_UPDATE = "Update";

  // PRIVILEDGE_CREATE_ADMIN = 0;
  // PRIVILEDGE_GET_ANY_ADMIN = 1;
  // PRIVILEDGE_UPDATE_ANY_ADMIN = 2;
  // PRIVILEDGE_RESET_PASSWORDS = 3;
  // PRIVILEDGE_DELETE_ANY_ADMIN = 4;
  // PRIVILEDGE_LOGOUT_ANY_ADMIN = 5;

  constructor(props) {
    super(props);

    this.props.onGetAllAdmins(this.state.page, this.state.limit);
  }

  componentDidMount() {}

  initialState={
    tab: "explore",

    id: "",
    name: "",
    username: "",
    password: "",
    email: "",
    phone: "",
    address: "",
    usertype: 4,
    priviledges: [],

    page: 1,
    limit: 20,

    mode: this._MODE_CREATE,

    renderProfile: {
      show: false,
    },
  }

  state = {
    ...this.initialState
  };

  clearInputs = () => {
    this.setState({
      id: "",
      name: "",
      username: "",
      password: "",
      email: "",
      phone: "",
      address: "",
      usertype: 4,
      priviledges: [],

      mode: this._MODE_CREATE,
    });
  };

  renderProfile = () => {
    const { renderProfile } = this.state;

    const { item, event, eventKey, i, show } = renderProfile;

    return (
      <Modal
        show={show}
        onEnter={() => item && this.props.onGetAdminById(item.id)}
        onHide={() => {
          this.dismissRenderProfile();
        }}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {`${item?.username ?? "Profile"} `}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AdminProfile notself id={item?.id} />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.dismissRenderProfile}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  dismissRenderProfile = () => {
    this.setState({
      renderProfile: {
        show: false,
      },
    });

    this.props.onClearAdminById();
  };

  actionItem = (item, i) => {
    const edit = <Tooltip id="edit_tooltip">Edit Admin</Tooltip>;

    const remove = <Tooltip id="remove_tooltip">Delete Admin</Tooltip>;

    const more = <Tooltip id="more_tooltip">More actions</Tooltip>;

    const moretitle = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">More</span>
      </div>
    );

    return (
      <React.Fragment key={i}>
        <td className="td-actions text-right">
          <OverlayTrigger placement="top" overlay={edit}>
            <Button
              bsStyle="info"
              simple
              type="button"
              bsSize="xs"
              disabled={
                !this.props.userPriviledges.some(
                  (val) => val === priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                )
              }
              onClick={(event, eventKey) => {
                // this.setState({ ...item, mode: "Update" });
                // window.scrollTo(0, 100);
                this.setState({
                  renderProfile: {
                    show: true,
                    item,
                    event,
                    eventKey,
                    i,
                  },
                });
              }}
              href="#edit"
            >
              <i className="fa fa-edit" />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={remove}>
            <Button
              bsStyle="danger"
              simple
              type="button"
              bsSize="xs"
              disabled={
                !this.props.userPriviledges.some(
                  (val) => val === priv.PRIVILEDGE_DELETE_ANY_ADMIN
                )
              }
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to delete this admin ?")
                ) {
                  this.props.onDeleteAdmin(item.id);
                }
              }}
            >
              <i className="fa fa-times" />
            </Button>
          </OverlayTrigger>
        </td>

        <td className="td-actions text-right">
          <OverlayTrigger placement="top" overlay={more}>
            <DropdownButton
              title={moretitle}
              pullRight
              noCaret
              id="dropdown-admin-more-actions"
            >
              <MenuItem
                eventKey={2.1}
                disabled={
                  !this.props.userPriviledges.some(
                    (val) => val === priv.PRIVILEDGE_GET_ANY_ADMIN
                  )
                }
                onSelect={(eventKey, event) => {
                  this.setState({
                    renderProfile: {
                      show: true,
                      item,
                      event,
                      eventKey,
                      i,
                    },
                  });
                }}
              >
                Full Profile
              </MenuItem>

              <MenuItem
                eventKey={2.3}
                disabled={
                  !this.props.userPriviledges.some(
                    (val) => val === priv.PRIVILEDGE_RESET_PASSWORDS
                  )
                }
                onSelect={(eventKey, event) => {
                  if (
                    window.confirm(
                      "Are you sure you want to reset this admin's password ?"
                    )
                  ) {
                    this.props.onResetAdminPassword(item.id);
                  }
                }}
              >
                Reset Password
              </MenuItem>

              <MenuItem
                eventKey={2.4}
                disabled={
                  !this.props.userPriviledges.some(
                    (val) => val === priv.PRIVILEDGE_LOGOUT_ANY_ADMIN
                  )
                }
                onSelect={(eventKey, event) => {
                  if (
                    window.confirm(
                      "Are you sure you want to logout this admin ?"
                    )
                  ) {
                    this.props.onLogoutAdmin(item.id);
                  }
                }}
              >
                Logout
              </MenuItem>
            </DropdownButton>
          </OverlayTrigger>
        </td>
      </React.Fragment>
    );
  };

  tabSwitcher = (tab) => {
    this.setState({ tab });
  };

  render() {
    const {
      onCreateAdmin,
      onGetAllAdmins,
      onGetAdminById,
      onUpdateAdmin,

      allAdms,
      total,
      adminID,
    } = this.props;

    const headers = [
      "Name",
      "Username",
      "Phone",
      "Email",
      "Address",
    ];
    
    let items = [];
    allAdms &&
      allAdms.forEach((element) => {
        items = [
          ...items,
          {
            id: element.id,
            name: element.name,
            username: element.username,
            phone: element.phone,
            email: element.email,
            address: element.address,
          },
        ];
      });

    const {
      tab,

      id,
      name,
      username,
      password,
      email,
      phone,
      address,
      priviledges,
      usertype,

      mode,
    } = this.state;

    return (
      <div className="content">

        {this.renderProfile()}

        <Grid fluid style={{ display: "none" }}>
          <Row>
            <Col md={12}>
              <Card
                title={`${mode} an Admin`}
                content={
                  <form>
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Name",
                          value: name,
                          onChange: (e) => {
                            let name = e.currentTarget.value;
                            this.setState({ name });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "Username",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Username",
                          value: username,
                          onChange: (e) => {
                            let username = e.currentTarget.value;
                            this.setState({ username });
                          },
                        },
                        {
                          label: "Password",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Password",
                          value: password,
                          onChange: (e) => {
                            let password = e.currentTarget.value;
                            this.setState({ password });
                          },
                        },
                        {
                          label: "Phone Number",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Phone Number",
                          value: phone,
                          onChange: (e) => {
                            let phone = e.currentTarget.value;
                            this.setState({ phone });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-4", "col-md-8"]}
                      properties={[
                        {
                          label: "Email",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: email,
                          onChange: (e) => {
                            let email = e.currentTarget.value;
                            this.setState({ email });
                          },
                        },
                        {
                          label: "Address",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Address",
                          value: address,
                          onChange: (e) => {
                            let address = e.currentTarget.value;
                            this.setState({ address });
                          },
                        },
                      ]}
                    />

                    <Button
                      bsStyle="default"
                      fill
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();

                        this.clearInputs();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      bsStyle="primary"
                      fill
                      pullRight
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm("Are you sure you want to proceed")
                        ) {
                          if (mode === this._MODE_CREATE) {
                            onCreateAdmin({
                              username,
                              password,
                              name,
                              email,
                              phone,
                              address,
                              usertype,
                              priviledges,
                            });
                          } else if (mode === this._MODE_UPDATE) {
                            onUpdateAdmin({
                              id,
                              username,
                              password,
                              name,
                              email,
                              phone,
                              address,
                              usertype,
                              priviledges,
                            });
                          }

                          this.clearInputs();
                        }
                      }}
                    >
                      {mode} Admin
                    </Button>

                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>

        <div style={{ display: tab === "explore" ? "block" : "none" }}>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <div>
                  <Button
                    fill
                    bsStyle="info"
                    disabled={
                      !this.props.userPriviledges.some(
                        (val) => val === priv.PRIVILEDGE_CREATE_ADMIN
                      )
                    }
                    onClick={(event) => {
                      this.setState({
                        renderProfile: {
                          show: true,
                        },
                      });
                    }}
                  >
                    Create Admin
                  </Button>
                  <Button
                    pullRight
                    simple
                    onClick={() =>
                      onGetAllAdmins(this.state.page, this.state.limit)
                    }
                  >
                    <img
                      src="/images/refresh.png"
                      alt="refresh admins"
                      width="20"
                      height="20"
                    />
                  </Button>
                </div>

                <ListView
                  items={items}
                  headers={headers}
                  title="Admin Details"
                  category="Admins"
                  actionItemEnd={this.actionItem}
                  controlledPage
                  page={this.state.page}
                  limit={this.state.limit}
                  handlePageChange={(newPageNum) => {
                    this.setState({ page: newPageNum });
                    onGetAllAdmins(newPageNum, this.state.limit);
                  }}
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
