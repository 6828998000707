const AdminLayoutConstants = {
  CHANGE_NOTIFICATION_SYSTEM: "CHANGE_NOTIFICATION_SYSTEM",

  CHANGE_SIDEBAR_BACKGROUND_IMAGE: "CHANGE_SIDEBAR_BACKGROUND_IMAGE",
  CHANGE_SIDEBAR_BACKGROUND_COLOR: "CHANGE_SIDEBAR_BACKGROUND_COLOR",

  TOGGLE_SIDEBAR_HAS_BACKGROUND_IMAGE: "TOGGLE_SIDEBAR_HAS_BACKGROUND_IMAGE",

  CHANGE_FIXED_CLASSESS: "CHANGE_FIXED_CLASSESS",

  RESTORE_ROOT_DEFAULT_STATE: "RESTORE_ROOT_DEFAULT_STATE",

  CHECK_ALL_PENDING_ACTIVITY: "CHECK_PENDING_ACTIVITY",

  TOGGLE_ALL_PENDING: "CHECK_ALL_PENDING"
};

export default AdminLayoutConstants;
