import React, { useState, useEffect } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import DefaultMap from "./Maps";
import { isOnLine } from "../../shared";

const GoogleMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyC9vuBWQ74uvM8M6IO_ce8MYPippvqRdL0&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap defaultZoom={8} defaultCenter={{ lat: props.lat, lng: props.lng }}>
    {props.isMarkerShown && (
      <Marker position={{ lng: props.lng, lat: props.lat }} />
    )}
  </GoogleMap>
));

export const Map = ({ lat, lng, locations, ...props }) => {
  const [state, setState] = useState({ onLine: false });

  useEffect(() => {
    isOnLine().then((onLine) => {
      setState({ onLine });
    });
    return () => {};
  }, []);

  const { onLine } = state;

  // console.log({ onLine });
  // console.log({ lat, lng });

  return onLine ? (
    <DefaultMap lat={lat} lng={lng} locations={locations} />
  ) : (
    <div></div>
  );
};

export default Map;
