import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getOrgById,
  resetOrgPassword,
  updateOrgById,
} from "../actions/index.actions";
import { Card } from "../components/Card/Card.jsx";
import Button from "../components/CustomButton/CustomButton.jsx";
import { FormInputs } from "../components/FormInputs/FormInputs.jsx";
import Map from "../components/Map";

const mapStateToProps = (state, props) => {
  const {
    loginOrganization,
    getOrganizationById,
  } = state.organization.response;

  const profile = props.notself ? getOrganizationById : loginOrganization;

  return {
    self: props.notself ? false : true,

    //id', 'username', 'name', 'email', 'phone', 'address', 'gps', 'verified', 'usertype', 'dateCreated', 'dateUpdated'

    id: props.id ? props.id : profile.id,
    username: profile.username ?? "",
    name: profile.name ?? "",
    phone: profile.phone ?? "",
    email: profile.email ?? "",
    address: profile.address ?? "",
    usertype: profile.usertype,
    gps: profile.gps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onProfileUpdate(details) {},

  onPasswordUpdate({ id, oldPassword, newPassword, confirmPassword }) {},

  onSuperProfileUpdate(details) {
    dispatch(updateOrgById(details));
  },

  onResetPassword(organizationID) {
    dispatch(resetOrgPassword(organizationID));
  },

  onSuperGetProfile(id) {
    dispatch(getOrgById(id));
  },
});

class OrganizationProfile extends Component {
  constructor(props) {
    super(props);

    !this.props.self && this.props.onSuperGetProfile(this.props.id);
  }

  componentDidMount() {
    this.setState({
      self: this.props.self,

      id: this.props.id,
      username: this.props.username,
      name: this.props.name,
      phone: this.props.phone,
      email: this.props.email,
      address: this.props.address,
      usertype: this.props.usertype,
      gps: this.props.gps,
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    prevProps.id !== this.props.id && this.setState({ id: this.props.id });
    prevProps.username !== this.props.username &&
      this.setState({ username: this.props.username });
    prevProps.name !== this.props.name &&
      this.setState({ name: this.props.name });
    prevProps.phone !== this.props.phone &&
      this.setState({ phone: this.props.phone });
    prevProps.email !== this.props.email &&
      this.setState({ email: this.props.email });
    prevProps.address !== this.props.address &&
      this.setState({ address: this.props.address });
    prevProps.usertype !== this.props.usertype &&
      this.setState({ usertype: this.props.usertype });
    prevProps.gps !== this.props.gps && this.setState({ gps: this.props.gps });
  };

  initialState = {
    self: true,

    id: "",
    username: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    gps: "",
    usertype: 1,

    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  state = { ...this.initialState };

  clearInputs = () => {
    this.setState({ ...this.initialState });
  };

  render() {
    const {
      onProfileUpdate,
      onPasswordUpdate,
      onSuperProfileUpdate,
      onResetPassword,
    } = this.props;

    const {
      self,

      id,
      username,
      name,
      phone,
      email,
      address,
      usertype,
      gps,

      oldPassword,
      newPassword,
      confirmPassword,
    } = this.state;

    const cords = gps?.split(",");

    const lat = parseFloat(cords ? cords[0] : 6.454712);
    const lng = parseFloat(cords ? cords[1] : 3.388761);

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Edit Profile"
                content={
                  <form>
                    <FormInputs
                      ncols={["col-md-8", "col-md-4"]}
                      properties={[
                        {
                          label: "Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Name",
                          value: name,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ name: value });
                          },
                        },
                        {
                          label: "GPS",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "GPS",
                          value: gps,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ gps: value });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-5", "col-md-7"]}
                      properties={[
                        {
                          label: "Username",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Username",
                          value: username,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ username: value });
                          },
                        },
                        {
                          label: "Email address",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: email,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ email: value });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-4", "col-md-8"]}
                      properties={[
                        {
                          label: "Phone Number",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Phone Number",
                          value: phone,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ phone: value });
                          },
                        },
                        {
                          label: "Adress",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Home Adress",
                          value: address,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ address: value });
                          },
                        },
                      ]}
                    />

                    <Button
                      bsStyle="info"
                      pullRight
                      fill
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm("Are you sure you want to proceed")
                        ) {
                          self
                            ? onProfileUpdate({
                                id,
                                username,
                                name,
                                phone,
                                email,
                                address,
                                gps,
                                usertype,
                              })
                            : onSuperProfileUpdate({
                                id,
                                username,
                                name,
                                phone,
                                email,
                                address,
                                gps,
                                usertype,
                              });
                        }
                      }}
                    >
                      Update Profile
                    </Button>

                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>

            <Col md={4}>
              {self ? (
                <Card
                  title="Change Password"
                  content={
                    <form>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Old password",
                            type: "password",
                            bsClass: "form-control",
                            placeholder: "Old password",
                            value: oldPassword,
                            onChange: (e) => {
                              let value = e.currentTarget.value;
                              this.setState({ oldPassword: value });
                            },
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "New Password",
                            type: "password",
                            bsClass: "form-control",
                            placeholder: "Password",
                            value: newPassword,
                            onChange: (e) => {
                              let value = e.currentTarget.value;
                              this.setState({ newPassword: value });
                            },
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Confirm password",
                            type: "password",
                            bsClass: "form-control",
                            placeholder: "Confirm password",
                            value: confirmPassword,
                            onChange: (e) => {
                              let value = e.currentTarget.value;
                              this.setState({ confirmPassword: value });
                            },
                          },
                        ]}
                      />

                      <Button
                        bsStyle="info"
                        pullRight
                        fill
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          if (
                            window.confirm("Are you sure you want to proceed")
                          ) {
                            onPasswordUpdate({
                              id,
                              oldPassword,
                              newPassword,
                              confirmPassword,
                            });
                          }
                        }}
                      >
                        Change Password
                      </Button>
                      <br />
                    </form>
                  }
                />
              ) : (
                <Button
                  fill
                  bsStyle="info"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Are you sure you want to reset the password"
                      )
                    ) {
                      onResetPassword(id);
                    }
                  }}
                >
                  Reset Password
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Map lat={lat} lng={lng} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationProfile);
