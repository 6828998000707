import FetchConstants from "./fetch/index.fetch.constants";
import { LayoutConstants } from "./layout/index.layout.constants";

const C = {
  /**
   * Fetch Constants
   */
  ...FetchConstants,

/**
 * Layout Constants
 */
  ...LayoutConstants
};

export default C;
