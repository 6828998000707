import { combineReducers } from "redux";
import response from "./response.reducer";


const userReducer = combineReducers({
  response,
  
});

export default userReducer;
