import C from "../../constants/index.constants";

/**
 * Base URL
 */
//#region
export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost/rema/apis/v1/admins"
    : "https://apis.touchandpay.me/rema/apis/v1/admins";
//#endregion

export const headers = () => ({
  Token: `Bearer ${localStorage.getItem("rema-token")}`,
  "Content-Type": "application/json",
});

export const fetchApi = ({ url, method, constant, body, }) => (
  dispatch
) => {
  dispatch({ type: C.FETCHING });

  return fetch(`${baseUrl}${url}`, {
    headers: headers(),
    method: method,
    [body ? "body" : ""]: body ? JSON.stringify({ data: body }) : "",
  })
    .then((res) => res.json())
    .then((resJson) => {
      dispatch({ type: C.FETCHING_FINISHED });

      let data = resJson.content.data;
      let error = resJson.error;
      let success = resJson.success;

      if (success.code >= 400) {
        dispatch({ type: C.ADD_ERROR, payload: error });
        return;
      }

      dispatch({ type: C.ADD_SUCCESS, payload: success });

      dispatch({ type: constant, payload: data });
      return;
    })
    .catch((error) => {
      dispatch({ type: C.ADD_ERROR, payload: error });
      dispatch({ type: C.FETCHING_FINISHED });
      return;
    });
};
