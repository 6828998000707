import { combineReducers } from "redux";
import C from "../../constants/index.constants";

const createAdmin = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_CREATE_ADMIN:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_CREATE_ADMIN:
      return {};

    default:
      return state;
  }
};

const loginAdmin = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_LOGIN_ADMIN:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_LOGIN_ADMIN:
      return {};

    default:
      return state;
  }
};

const getAdminDashboard = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_DASHBOARD_ADMIN:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_DASHBOARD_ADMIN:
      return {};

    default:
      return state;
  }
};

const getAllAdmins = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ALL_ADMINS:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_GET_ALL_ADMINS:
      return {};

    default:
      return state;
  }
};

const getAdmin = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ADMIN:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_GET_ADMIN:
      return {};

    default:
      return state;
  }
};

const getAdminById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ADMIN_BY_ID:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_GET_ADMIN_BY_ID:
      return {};

    default:
      return state;
  }
};

const getAdminActivities = (state = [], action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ADMIN_ACTIVITIES:
      return [...action.payload];

    case C.CLEAR_RESPONSE_GET_ADMIN_ACTIVITIES:
      return [];

    default:
      return state;
  }
};

const getAdminByIdActivities = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ADMIN_BY_ID_ACTIVITIES:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_GET_ADMIN_BY_ID_ACTIVITIES:
      return {};

    default:
      return state;
  }
};

const updateAdmin = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_UPDATE_ADMIN:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_UPDATE_ADMIN:
      return {};

    default:
      return state;
  }
};

const updateAdminById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_UPDATE_ADMIN_BY_ID:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_UPDATE_ADMIN_BY_ID:
      return {};

    default:
      return state;
  }
};

const updateAdminPassword = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_UPDATE_ADMIN_PASSWORD:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_UPDATE_ADMIN_PASSWORD:
      return {};

    default:
      return state;
  }
};

const resetAdminPassword = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_RESET_ADMIN_PASSWORD:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_RESET_ADMIN_PASSWORD:
      return {};

    default:
      return state;
  }
};

const deleteAdmin = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_DELETE_ADMIN:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_DELETE_ADMIN:
      return {};

    default:
      return state;
  }
};

const deleteAdminById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_DELETE_ADMIN_BY_ID:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_DELETE_ADMIN_BY_ID:
      return {};

    default:
      return state;
  }
};

const logoutAdmin = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_LOGOUT_ADMIN:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_LOGOUT_ADMIN:
      return {};

    default:
      return state;
  }
};

const logoutAdminById = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_LOGOUT_ADMIN_BY_ID:
      return { ...action.payload };

    case C.CLEAR_RESPONSE_LOGOUT_ADMIN_BY_ID:
      return {};

    default:
      return state;
  }
};

const response = combineReducers({
  getAdminDashboard,
  createAdmin,
  loginAdmin,
  getAllAdmins,
  getAdmin,
  getAdminById,
  getAdminActivities,
  getAdminByIdActivities,
  updateAdmin,
  updateAdminById,
  updateAdminPassword,
  resetAdminPassword,
  deleteAdmin,
  deleteAdminById,
  logoutAdmin,
  logoutAdminById,
});

export default response;
