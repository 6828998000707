import C from "../../constants/index.constants";
import { headers } from "../index.actions";
import { baseUrl, fetchApi } from "./base.action";

export const createUser = ({
  name,
  email,
  phone,
  address,
  gender,
  gps,
  image,
}) => (dispatch) => {
  if (!name || !email || !phone || !address || !gps || !image) {
    !name && dispatch({ type: C.INCOMPLETE_FIELD, payload: "name" });

    !email && dispatch({ type: C.INCOMPLETE_FIELD, payload: "email" });

    !phone && dispatch({ type: C.INCOMPLETE_FIELD, payload: "phone" });

    !address && dispatch({ type: C.INCOMPLETE_FIELD, payload: "address" });

    !gps && dispatch({ type: C.INCOMPLETE_FIELD, payload: "gps" });

    !image && dispatch({ type: C.INCOMPLETE_FIELD, payload: "image" });
  }

  dispatch({ type: C.FETCHING });

  fetch(`${baseUrl}/create/user`, {
    headers: headers(),
    method: "POST",
    body: JSON.stringify({
      data: { name, email, phone, address, gender, gps, image },
    }),
  })
    .then((res) => res.json())
    .then((resJson) => {
      dispatch({ type: C.FETCHING_FINISHED });

      let data = resJson.content.data;
      let error = resJson.error;
      let success = resJson.success;
      if (success.code >= 400) {
        dispatch({ type: C.ADD_ERROR, payload: error });
        return;
      }

      dispatch({
        type: C.SET_RESPONSE_CREATE_USER,
        payload: data,
      });
      dispatch({ type: C.ADD_SUCCESS, payload: success });

      return;
    })
    .catch((error) => {
      dispatch({ type: C.ADD_ERROR, payload: error });
      dispatch({ type: C.FETCHING_FINISHED });
      return;
    });
};

export const getAllUsers = (page, limit) => {
  const url =
      !limit && !page
        ? `/get/users`
        : !limit && page
        ? `/get/users/${page}`
        : `/get/users/${page}/${limit}`,
    constant = C.SET_RESPONSE_GET_ALL_USERS,
    method = "GET";

  return fetchApi({
    url,
    method,
    constant,
  });
};

export const getUserById = (id) => {
  const url = `/get/user/${id}`,
    constant = C.SET_RESPONSE_GET_USER_BY_ID,
    method = "GET";

  return fetchApi({
    url,
    method,
    constant,
  });
};

export const getUserByUserId = (userID) => {
  const url = `/get/user/userID/${userID}`,
    constant = C.SET_RESPONSE_GET_USER_BY_ID,
    method = "GET";

  return fetchApi({
    url,
    method,
    constant,
  });
};

export const getUserByIdActivities = (id) => {
  const url = `/get/user/${id}/activities`,
    constant = C.SET_RESPONSE_GET_USER_BY_ID_ACTIVITIES,
    method = "GET";

  return fetchApi({
    url,
    method,
    constant,
  });
};

export const updateUserById = ({
  id,
  name,
  email,
  phone,
  address,
  gender,
  gps,
  image,
}) => (dispatch) => {
  if (!name || !email || !phone || !address || !gender || !gps || !image) {
    !name && dispatch({ type: C.INCOMPLETE_FIELD, payload: "name" });

    !email && dispatch({ type: C.INCOMPLETE_FIELD, payload: "email" });

    !phone && dispatch({ type: C.INCOMPLETE_FIELD, payload: "phone" });

    !address && dispatch({ type: C.INCOMPLETE_FIELD, payload: "address" });

    !gender && dispatch({ type: C.INCOMPLETE_FIELD, payload: "gender" });

    !gps && dispatch({ type: C.INCOMPLETE_FIELD, payload: "gps" });

    !image && dispatch({ type: C.INCOMPLETE_FIELD, payload: "image" });

    return;
  }

  dispatch({ type: C.FETCHING });

  fetch(`${baseUrl}/update/user`, {
    headers: headers(),
    method: "PUT",
    body: JSON.stringify({
      data: {
        id,
        name,
        email,
        phone,
        address,
        gender,
        gps,
        image,
      },
    }),
  })
    .then((res) => res.json())
    .then((resJson) => {
      dispatch({ type: C.FETCHING_FINISHED });

      let data = resJson.content.data;
      let error = resJson.error;
      let success = resJson.success;
      if (success.code >= 400) {
        dispatch({ type: C.ADD_ERROR, payload: error });
        return;
      }

      dispatch({
        type: C.SET_RESPONSE_UPDATE_USER_BY_ID,
        payload: data,
      });
      dispatch({ type: C.ADD_SUCCESS, payload: success });

      return;
    })
    .catch((error) => {
      dispatch({ type: C.ADD_ERROR, payload: error });
      dispatch({ type: C.FETCHING_FINISHED });
      return;
    });
};

export const verifyUser = (id) => {
  const url = `/verify/user`,
    constant = C.SET_RESPONSE_VERIFY_USER,
    method = "PUT",
    body = { id: id };

  return fetchApi({
    url,
    method,
    constant,
    body,
  });
};

export const deleteUserById = (id) => {
  const url = `/delete/user/${id}`,
    constant = C.SET_RESPONSE_DELETE_USER_BY_ID,
    method = "DELETE";

  return fetchApi({
    url,
    method,
    constant,
  });
};
