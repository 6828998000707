import React, { Component } from "react";
import {
  Col,
  DropdownButton,
  Grid,
  MenuItem,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  clearGetActsByDate,
  clearGetUsrByIdActivities,
  createUsr,
  deleteUsrById,
  getAllUsrs,
  getUsrById,
  getUsrByIdActivities,
  updateUsrById,
} from "../actions/index.actions";
import Button from "../components/CustomButton/CustomButton";
import ListView from "../components/Customs/ListView";
import Activities from "./UserActivities";
import UserProfile from "./UserProfile";
import Map from "../components/Map";
import { baseUrl } from "../actions/fetch/base.action";

const mapStateToProps = (state, props) => {
  const {
    getAllUsers: allUsrs,
    getUserByIdActivities: usrByIdActivities,
  } = state.user.response;

  return {
    props,
    allUsrs: allUsrs.all ?? [],
    allUsrsTotal: allUsrs.total,
    usrByIdActivities: usrByIdActivities.activitys,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateUser(details) {
    dispatch(createUsr(details));
  },
  onGetAllUsers(page, limit) {
    dispatch(getAllUsrs(page, limit));
  },
  onGetUser(userID) {
    dispatch(getUsrById(userID));
  },
  onGetUserActivities(userID) {
    dispatch(getUsrByIdActivities(userID));
    dispatch(clearGetActsByDate());
  },

  onClearUserActivities() {
    // dispatch(clearGetUsrByIdActivities());
  },
  onUpdateUser(details) {
    dispatch(updateUsrById(details));
  },
  onDeleteUser(userID) {
    dispatch(deleteUsrById(userID));
  },
});

class User extends Component {
  _MODE_CREATE = "Create";
  _MODE_UPDATE = "Update";

  constructor(props) {
    super(props);

    this.props.onGetAllUsers(this.state.page, this.state.limit);
  }

  initialState = {
    tab: "explore",

    id: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    gender: "",
    gps: "",
    image: "",

    page: 1,
    limit: 20,

    mode: this._MODE_CREATE,

    renderActivities: {
      show: false,
      item: null,
      event: null,
      eventKey: null,
      i: null,
    },

    renderProfile: {
      show: false,
      item: null,
      event: null,
      eventKey: null,
      i: null,
    },

    renderLocation: {
      show: false,
      item: null,
      event: null,
      eventKey: null,
      i: null,
    },
  };

  state = {
    ...this.initialState,
  };

  clearInputs = () => {
    this.setState({
      ...this.initialState,
    });
  };

  renderActivities = () => {
    const { renderActivities } = this.state;

    const { item, event, eventKey, i, show } = renderActivities;

    const { usrByIdActivities } = this.props;

    return (
      <Modal
        show={show}
        onEnter={() => {
          item && this.props.onGetUserActivities(item.id);
        }}
        onHide={() => {
          this.dismissRenderActivites();
        }}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {`${item?.name} Activities`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Activities
            activities={usrByIdActivities}
            idObj={{ userID: item?.id }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.dismissRenderActivites}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  dismissRenderActivites = () => {
    this.setState({
      renderActivities: {
        show: false,
      },
    });

    this.props.onClearUserActivities();
  };

  renderProfile = () => {
    const { renderProfile } = this.state;

    const { item, event, eventKey, i, show } = renderProfile;

    return (
      <Modal
        show={show}
        // onEnter={() => item && this.props.onGetUserById(item.id)}
        onHide={() => {
          this.dismissRenderProfile();
        }}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {`${item?.username ?? "Profile"} `}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <UserProfile notself id={item?.id} {...item} />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.dismissRenderProfile}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  dismissRenderProfile = () => {
    this.setState({
      renderProfile: {
        show: false,
      },
    });

    // this.props.onClearUserById();
  };

  renderLocation = () => {
    const { renderLocation } = this.state;

    const { item, event, eventKey, i, show } = renderLocation;
    const gps = item?.gps?.split(",");

    const lat = parseFloat(gps ? gps[0] : 0);
    const lng = parseFloat(gps ? gps[1] : 0);

    return (
      <Modal
        show={show}
        onEnter={() => {
          item && (() => true)();
        }}
        onHide={() => {
          this.dismissRenderLocation();
        }}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            {`${item?.name} Location`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Map lat={lat} lng={lng} />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.dismissRenderLocation}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  dismissRenderLocation = () => {
    this.setState({
      renderLocation: {
        show: false,
      },
    });
  };

  actionItem = (item, i) => {
    const edit = <Tooltip id="edit_tooltip">Edit User</Tooltip>;

    const remove = <Tooltip id="remove_tooltip">Delete User</Tooltip>;

    const more = <Tooltip id="more_tooltip">More actions</Tooltip>;

    const moretitle = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">More</span>
      </div>
    );

    return (
      <React.Fragment key={i}>
        {/* <td className="td-actions text-right">
          <OverlayTrigger placement="top" overlay={edit}>
            <Button
              bsStyle="info"
              simple
              type="button"
              bsSize="xs"
              onClick={() => {
                this.setState({ ...item, mode: "Update" });
                window.scrollTo(0, 100);
              }}
              href="#edit"
            >
              <i className="fa fa-edit" />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={remove}>
            <Button
              bsStyle="danger"
              simple
              type="button"
              bsSize="xs"
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to delete this user ?")
                ) {
                  this.props.onDeleteUser(item.id);
                }
              }}
            >
              <i className="fa fa-times" />
            </Button>
          </OverlayTrigger>
        </td> */}

        <td className="td-actions text-right">
          <OverlayTrigger placement="top" overlay={more}>
            <DropdownButton
              title={moretitle}
              pullRight
              noCaret
              id="dropdown-user-more-actions"
            >
              <MenuItem
                eventKey={2.2}
                onSelect={(eventKey, event) => {
                  this.setState({
                    renderProfile: {
                      show: true,
                      item: item,
                      event,
                      eventKey,
                      i,
                    },
                  });
                }}
              >
                Full Profile
              </MenuItem>
              <MenuItem
                eventKey={2.3}
                onSelect={(eventKey, event) => {
                  this.setState({
                    renderActivities: {
                      show: true,
                      item: item,
                      event,
                      eventKey,
                      i,
                    },
                  });
                }}
              >
                Activities
              </MenuItem>
              <MenuItem
                eventKey={2.4}
                onSelect={(eventKey, event) => {
                  this.setState({
                    renderLocation: {
                      show: true,
                      item: item,
                      event,
                      eventKey,
                      i,
                    },
                  });
                }}
              >
                Location
              </MenuItem>
              <MenuItem
                eventKey={2.5}
                onSelect={(eventKey, event) => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this user ?"
                    )
                  ) {
                    this.props.onDeleteUser(item.id);
                  }
                }}
              >
                Delete
              </MenuItem>
              f
            </DropdownButton>
          </OverlayTrigger>
        </td>
      </React.Fragment>
    );
  };

  tabSwitcher = (tab) => {
    this.setState({ tab });
  };

  render() {
    const {
      onCreateUser,
      onGetAllUsers,
      onGetUser,
      onGetUserById,
      onUpdateUser,
      onChangeUserPage,

      allUsrs,
      adminID,
      allUsrsTotal,
    } = this.props;

    const headers = [
      "User ID",
      "Name",
      "Phone",
      "Email",
      "Gender",
      "Address",
      "Display picture",
    ];

    let items = [];
    allUsrs.forEach((element) => {
      items = [
        ...items,
        {
          id: element.id,
          userID: element.userID,
          name: element.name,
          phone: element.phone,
          email: element.email,
          gender: element.gender,
          address: element.address,
          gps: element.gps,
          image: element.image,
        },
      ];
    });

    const {
      tab,

      id,
      name,
      phone,
      email,
      gender,
      address,
      gps,
      image,

      mode,
    } = this.state;

    return (
      <div className="content">
        {this.renderActivities()}
        {this.renderLocation()}
        {this.renderProfile()}

        <div style={{ display: tab === "explore" ? "block" : "none" }}>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <div>
                  <Button
                    pullRight
                    simple
                    onClick={() =>
                      onGetAllUsers(this.state.page, this.state.limit)
                    }
                  >
                    <img
                      src="/images/refresh.png"
                      alt="refresh users"
                      width="20"
                      height="20"
                    />
                  </Button>
                </div>

                <ListView
                  items={items}
                  headers={headers}
                  title="User Details"
                  category="Users"
                  actionOnItemAt={[{ index: 8, action: this.renderDP }]}
                  actionItemEnd={this.actionItem}
                  controlledPage
                  page={this.state.page}
                  limit={this.state.limit}
                  handlePageChange={(newPageNum) => {
                    this.setState({ page: newPageNum });
                    onGetAllUsers(newPageNum, this.state.limit);
                  }}
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }

  renderDP = (item, colIndex, rowIndex) => {
    return (
      <td key={colIndex}>
        <span
          style={{ width: 40, height: 40, borderRadius: 20, margin: "auto" }}
        >
          <a
            href={`${baseUrl}/${item.image}`}
            // href="http://www.mcsb.com/wp-content/uploads/2017/03/40915906_ml.jpg"
          >
            <img
              src={`${baseUrl}/${item.image}`}
              // src="http://www.mcsb.com/wp-content/uploads/2017/03/40915906_ml.jpg"
              className=""
              style={{ width: 40, height: 40 }}
            />
          </a>
        </span>
      </td>
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
