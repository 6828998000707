import C from "../../constants/index.constants";
import { baseurl } from "../index.actions";
import { fetchApi } from "./base.action";

export const getAllActivities = (page, limit) => {
  const url =
      !limit && !page
        ? `${baseurl}/get/activities`
        : !limit && page
        ? `${baseurl}/get/activities/${page}`
        : `${baseurl}/get/activities/${page}/${limit}`,
    constant = C.SET_RESPONSE_GET_ALL_ACTIVITIES,
    method = "GET";

  return fetchApi({
    url,
    method,
    constant,
  });
};

export const getActivitiesByDate = (fromDate, toDate) => {
  const url = `/get/activities/date/${fromDate}/${toDate}`,
    constant = C.SET_RESPONSE_GET_ACTIVITIES_BY_DATE,
    method = "GET";

  return fetchApi({
    url,
    method,
    constant,
  });
};

export const getActivitiesByUserID = (userID) => {
  const url = `/get/activities/userID/${userID}`,
    constant = C.SET_RESPONSE_GET_ACTIVITIES_BY_USER_ID,
    method = "GET";

  return fetchApi({
    url,
    method,
    constant,
  });
};

export const getActivitiesByOrganizationID = (organizationID) => {
  const url = `/get/activities/organizationID/${organizationID}`,
    constant = C.SET_RESPONSE_GET_ACTIVITIES_BY_ORGANIZATION_ID,
    method = "GET";

  return fetchApi({
    url,
    method,
    constant,
  });
};
