import UserConstants from "./user.constants";
import OrganizationConstants from "./organization.constants";
import ActivityConstants from "./activity.constants";
import AdminConstants from "./admin.constants";

const FetchConstants = {
  REDIRECT_LOGIN: "REDIRECT_LOGIN",

  INCOMPLETE_FIELD: "INCOMPLETE_FIELD",

  ADD_ERROR: "ADD_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",
  CLEAR_ALL_ERRORS: "CLEAR_ALL_ERRORS",

  ADD_SUCCESS: "ADD_SUCCESS",
  CLEAR_SUCCESS: "CLEAR_SUCCESS",
  CLEAR_ALL_SUCCESSES: "CLEAR_ALL_SUCCESSES",

  FETCHING: "FETCHING",
  FETCHING_FINISHED: "FETCHING_FINISHED",

  SET_RESPONSE_TOKEN: "SET_RESPONSE_TOKEN",
  CLEAR_RESPONSE_TOKEN: "CLEAR_RESPONSE_TOKEN",

  /**
   * Admin
   */
  ...AdminConstants,

  /**
   * Organization
   */
  ...OrganizationConstants,

  /**
   * User
   */
  ...UserConstants,

  /**
   * Activity
   */
  ...ActivityConstants,
};
export default FetchConstants;
