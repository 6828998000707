import React from "react";
import { Pager, Pagination, Table } from "react-bootstrap";
import Card from "../Card/Card";

// ListView.prototype = {
//     items: PropTypes, headers, title, category, actionItemStart, actionItemAfterIndex , actionItemEnd
// }

/**
 * @param Array items
 * @param Array headers
 * @param String title
 * @param String category
 * @param Bool controlledPage
 * @param Bool selfedPage
 * @param Number page
 * @param Number limit
 * @param callback handlePageChange
 * @param callback actionItemStart(item , i)
 * @param callback actionItemEnd(item, i)
 * @param Array actionOnItemAt = [{index, callback(item, i)}]
 * @param Array renderItemAtIndex = [{ index, header, action: (item, i) => { } }]
 */

const ListView = (props) => {
  const {
    items,
    headers,
    title,
    category,
    controlledPage,
    selfedPage,
    page,
    limit,
    maxpage = 0,
    handlePageChange = (page, limit) => {},
    actionItemStart = (item, rowIndex) => {},
    actionItemEnd = (item, rowIndex) => {},
    actionOnItemAt = [{ index: -1, action: (item, colIndex, rowIndex) => {} }],
    renderItemAtIndex = [
      { index: -1, header: "", action: (item, colIndex, rowIndex) => {} },
    ],
    footers = [],
  } = props;

  let pagedItems = [];
  let newHeaders = [];
  let newFooters = [];

  let k = 0;

  headers.map((header, headerIndex) => {
    let indexes = [];

    renderItemAtIndex.map((item, itemIndex) => {
      let newHeader = item.header;

      indexes = [...indexes, item.index];

      newHeaders[item.index] = newHeader;
      newFooters[item.index] = "";
    });

    k = indexes.some((value) => value === k) ? k + 1 : k + 0;

    newHeaders[k] = headers[headerIndex];
    newFooters[k] = footers[headerIndex];

    k++;
  });

  if (selfedPage) {
    let min = page * limit - limit;
    let max = page * limit;

    pagedItems = items.filter((item, i) => i >= min && i < max);
  } else {
    pagedItems = items;
  }

  const pagedItemsLength = pagedItems.length;
  const itemsLength = items.length;

  return (
    <Card
      title={title}
      category={category}
      ctTableFullWidth
      ctTableResponsive
      content={
        <div style={{ overflowX: "scroll" }}>
          <div></div>
          <Table striped hover>
            <thead>
              <tr>
                <th>S/N</th>
                {newHeaders.map((newheader, headerIndex) => {
                  return (
                    newheader !== "id" && (
                      <th key={headerIndex}> {newheader} </th>
                    )
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {pagedItems &&
                pagedItems.map((row, rowIndex) => {
                  return (
                    <tr key={rowIndex}>
                      {actionItemStart(row, rowIndex)}

                      <td>
                        {controlledPage || selfedPage
                          ? page * limit - limit + rowIndex + 1
                          : rowIndex + 1}
                      </td>

                      {Object.keys(row).map((col, colIndex) => {
                        for (let k = 0; k < renderItemAtIndex.length; k++) {
                          const itemAtIndexElement = renderItemAtIndex[k];

                          if (itemAtIndexElement.index === colIndex) {
                            let tag = itemAtIndexElement.action(
                              row,
                              colIndex,
                              rowIndex
                            );

                            return (
                              col !== "id" && (
                                <React.Fragment key={colIndex}>
                                  {tag}
                                  <td>{row[col]}</td>
                                </React.Fragment>
                              )
                            );
                          }
                        }

                        for (let m = 0; m < actionOnItemAt.length; m++) {
                          let actionOnItemElemtent = actionOnItemAt[m];

                          if (actionOnItemElemtent.index === colIndex) {
                            return actionOnItemElemtent.action(
                              row,
                              colIndex,
                              rowIndex
                            );
                          }
                        }

                        return (
                          col !== "id" &&
                          col !== "gps" &&
                          (!col.includes("ID", 0) ||
                            col === "referenceID" ||
                            col === "userID") && (
                            <td key={colIndex}>{row[col]}</td>
                          )
                        );
                      })}

                      {actionItemEnd(row, rowIndex)}
                    </tr>
                  );
                })}

              <tr>
                <td></td>
                {newFooters &&
                  newFooters.map((footer, footerIndex) => {
                    return <td key={footerIndex}>{footer}</td>;
                  })}
              </tr>
            </tbody>
          </Table>

          {controlledPage && (
            <Pager >
              <Pager.Item
                previous
                eventKey="1"
                href={`#${page}`}
                disabled={page - 1 === 0}
                onClick={() => {
                  let newPageNum = page - 1;
                  handlePageChange(newPageNum);
                }}
              >
                &larr; Previous Page
              </Pager.Item>

              {Array(page)
                .fill(0)
                .map((item, controlledPageIndex) => {
                  let pageNum = controlledPageIndex + 1;
                  return (
                    <Pagination.Item
                      key={controlledPageIndex}
                      // eventKey={`${i + 3}`}
                      href={`#${pageNum}`}
                      active={pageNum === page}
                      onClick={() => {
                        handlePageChange(pageNum);
                      }}
                    >
                      {controlledPageIndex + 1}
                    </Pagination.Item>
                  );
                })}

              <Pager.Item
                next
                eventKey="2"
                href={`#${page}`}
                disabled={itemsLength < limit}
                onClick={() => {
                  let newPageNum = page + 1;
                  handlePageChange(newPageNum);
                }}
              >
                Next Page &rarr;
              </Pager.Item>
            </Pager>
          )}

          {selfedPage && (
            <Pager>
              <Pager.Item
                eventKey="1"
                href={`#${page}`}
                disabled={page - 1 === 0}
                onClick={() => {
                  let newPageNum = page - 1;
                  handlePageChange(newPageNum);
                }}
              >
                &larr; Previous Page
              </Pager.Item>

              {Array(Math.ceil(itemsLength / limit))
                .fill(0)
                .map((item, selfedPageIndex) => {
                  let page = selfedPageIndex + 1;
                  return (
                    <Pagination.Item
                      key={selfedPageIndex}
                      // eventKey={`${i + 3}`}
                      href={`#${page}`}
                      active={page === selfedPageIndex + 1}
                      onClick={() => {
                        handlePageChange(page);
                      }}
                    >
                      {selfedPageIndex + 1}
                    </Pagination.Item>
                  );
                })}

              <Pager.Item
                eventKey="2"
                href={`#${page}`}
                disabled={pagedItemsLength < limit || pagedItemsLength === 0}
                onClick={() => {
                  let newPageNum = page + 1;
                  handlePageChange(newPageNum);
                }}
              >
                Next Page &rarr;
              </Pager.Item>
            </Pager>
          )}

          {/* <Pagination>
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />

            <Pagination.Item>{10}</Pagination.Item>
            <Pagination.Item>{11}</Pagination.Item>
            <Pagination.Item active>{12}</Pagination.Item>
            <Pagination.Item>{13}</Pagination.Item>
            <Pagination.Item disabled>{14}</Pagination.Item>

            <Pagination.Ellipsis />
            <Pagination.Item>{20}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last />
          </Pagination> */}
        </div>
      }
    />
  );
};

export default ListView;
