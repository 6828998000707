import { combineReducers } from "redux";
import C from "../../constants/index.constants";

const getAllActivities = (state = {}, action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ALL_ACTIVITIES:
      return {...action.payload};

    case C.CLEAR_RESPONSE_GET_ALL_ACTIVITIES:
      return {};

    default:
      return state;
  }
};

const getActivitiesByDate = (state = [], action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ACTIVITIES_BY_DATE:
      return [...action.payload];

    case C.CLEAR_RESPONSE_GET_ACTIVITIES_BY_DATE:
      return [];

    default:
      return state;
  }
};

const getActivitiesByUserID = (state = [], action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ACTIVITIES_BY_USER_ID:
      return [...action.payload];

    case C.CLEAR_RESPONSE_GET_ACTIVITIES_BY_USER_ID:
      return [];

    default:
      return state;
  }
};

const getActivitiesByOrganizationID = (state = [], action) => {
  switch (action.type) {
    case C.SET_RESPONSE_GET_ACTIVITIES_BY_ORGANIZATION_ID:
      return [...action.payload];

    case C.CLEAR_RESPONSE_GET_ACTIVITIES_BY_ORGANIZATION_ID:
      return [];

    default:
      return state;
  }
};

const response = combineReducers({
  getAllActivities,
  getActivitiesByDate,
  getActivitiesByUserID,
  getActivitiesByOrganizationID,
});

export default response;
