import React from "react";
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import Button from "../CustomButton/CustomButton";

export const Datepicker = (props) => {
  const { fromDate, toDate, handleFromDate, handleToDate, handleClick } = props;
  return (
    <Grid fluid>
      <Row>
        <Col md={5}>
          <FormGroup>
            <ControlLabel>Activity Date From</ControlLabel>
            <FormControl
              type="date"
              value={new Date(fromDate * 1000).toISOString().substring(0, 10)}
              onChange={(e) => {
                handleFromDate((Date.parse(e.target.value) / 1000).toFixed(0));
              }}
            />
          </FormGroup>
        </Col>

        <Col md={5}>
          <FormGroup>
            <ControlLabel>Activity Date To</ControlLabel>
            <FormControl
              type="date"
              value={new Date(toDate * 1000).toISOString().substring(0, 10)}
              onChange={(e) => {
                handleToDate((Date.parse(e.target.value) / 1000).toFixed(0));
              }}
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <></>
          <Button
            fill
            bsStyle="info"
            block
            style={{ marginTop: "22px" }}
            onClick={handleClick}
          >
            Get
          </Button>
        </Col>
      </Row>
    </Grid>
  );
};

export default Datepicker;