import React from 'react';
import C from "../constants/index.constants"
import { Redirect } from "react-router"

export const redirectLogin = store => next => action => {
  let result;

  result = next(action)

  if (action.type === C.ADD_ERROR && action.payload.message.toLowerCase().indexOf("expired session") >= 0) {

    window.location.pathname = "/admin/login"

  }

  return result
}