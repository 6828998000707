import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  createAdm,
  getAdmById,
  resetAdmPassword,
  updateAdmById,
  updateSelfAdm,
  updateSelfAdmPassword,
} from "../actions/index.actions";
import { Card } from "../components/Card/Card";
import Button from "../components/CustomButton/CustomButton";
import { FormInputs } from "../components/FormInputs/FormInputs";
import { priviledges as priv } from "../variables/AppVariables";

const mapStateToProps = (state, props) => {
  const { loginAdmin: login, getAdminById } = state.admin.response;

  const profile = props.notself ? getAdminById : login;

  return {
    notself: props.notself,

    id: props.id ?? profile.id,
    username: profile.username ?? "",
    name: profile.name ?? "",
    phone: profile.phone ?? "",
    email: profile.email ?? "",
    address: profile.address ?? "",
    usertype: profile.usertype,

    priviledges:
      profile.priviledges && !Array.isArray(profile.priviledges)
        ? JSON.parse(profile.priviledges)
        : profile.priviledges ?? [],

    userPriviledges: !Array.isArray(login.priviledges)
      ? JSON.parse(login.priviledges)
      : login.priviledges,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateAdmin(details) {
    dispatch(createAdm(details));
  },

  onGetAdmById(adminID) {
    dispatch(getAdmById(adminID));
  },

  onProfileUpdate(details) {
    dispatch(updateSelfAdm(details));
  },

  onSuperProfileUpdate(details) {
    dispatch(updateAdmById(details));
  },

  onPasswordUpdate({ id, oldPassword, newPassword, confirmPassword }) {
    dispatch(
      updateSelfAdmPassword({ id, oldPassword, newPassword, confirmPassword })
    );
  },

  onResetPassword(adminID) {
    dispatch(resetAdmPassword(adminID));
  },
});

class AdminProfile extends Component {
  _CREATE_MODE = "Create";
  _UPDATE_SELF_MODE = "Update";
  _UPDATE_OTHER_MODE = "Update admin's";

  constructor(props) {
    super(props);

    this.props.notself &&
      this.props.id &&
      this.props.onGetAdmById(this.props.id);
  }

  componentDidMount = () => {
    this.setState({
      id: this.props.id,
      username: this.props.username,
      name: this.props.name,
      phone: this.props.phone,
      email: this.props.email,
      address: this.props.address,
      // usertype: this.props.usertype,
      priviledges: this.props.priviledges,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    prevProps.id !== this.props.id && this.setState({ id: this.props.id });
    prevProps.username !== this.props.username &&
      this.setState({ username: this.props.username });
    prevProps.name !== this.props.name &&
      this.setState({ name: this.props.name });
    prevProps.phone !== this.props.phone &&
      this.setState({ phone: this.props.phone });
    prevProps.email !== this.props.email &&
      this.setState({ email: this.props.email });
    prevProps.address !== this.props.address &&
      this.setState({ address: this.props.address });
    // prevProps.usertype !== this.props.usertype &&
    // this.setState({ usertype: this.props.usertype });
    prevProps.priviledges !== this.props.priviledges &&
      this.setState({ priviledges: this.props.priviledges });
  };

  state = {
    id: "",
    username: "",
    password: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    usertype: 0,
    priviledges: [],

    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  clearInputs = () => {
    this.setState({
      id: "",
      username: "",
      password: "",
      name: "",
      phone: "",
      email: "",
      address: "",
      usertype: 0,
      priviledges: [],

      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  render() {
    const {
      onCreateAdmin,
      onProfileUpdate,
      onSuperProfileUpdate,
      onPasswordUpdate,
      onResetPassword,

      notself,
    } = this.props;

    const {
      id,
      username,
      password,
      name,
      phone,
      email,
      address,
      usertype,
      priviledges,

      oldPassword,
      newPassword,
      confirmPassword,
    } = this.state;

    const mode =
      this.props.notself && !this.props.id
        ? this._CREATE_MODE
        : this.props.notself && this.props.id
        ? this._UPDATE_OTHER_MODE
        : this._UPDATE_SELF_MODE;

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Edit Admin Profile"
                content={
                  <form>
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Name",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Name",
                          value: name,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ name: value });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Email address",
                          type: "email",
                          required: true,
                          bsClass: "form-control",
                          placeholder: "Email",
                          value: email,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ email: value });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-6", "col-md-6"]}
                      properties={[
                        {
                          label: "Username",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Username",
                          value: username,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ username: value });
                          },
                        },
                        {
                          label: "Phone Number",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Phone Number",
                          value: phone,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ phone: value });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Adress",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Home Adress",
                          value: address,
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({ address: value });
                          },
                        },
                      ]}
                    />

                    <FormInputs
                      ncols={[
                        "col-md-3",
                        "col-md-3",
                        "col-md-3",
                        "col-md-3",
                        "col-md-3",
                        "col-md-3",
                      ]}
                      properties={[
                        {
                          label: "Create Admin",
                          type: "checkbox",
                          bsClass: "form-control",
                          checked: priviledges?.some(
                            (val) => val === priv.PRIVILEDGE_CREATE_ADMIN
                          ),
                          disabled: !this.props.userPriviledges.some(
                            (val) => val === priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                          ),
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({
                              priviledges: priviledges?.some(
                                (val) => val === priv.PRIVILEDGE_CREATE_ADMIN
                              )
                                ? priviledges.filter(
                                    (val) =>
                                      val !== priv.PRIVILEDGE_CREATE_ADMIN
                                  )
                                : [
                                    ...priviledges,
                                    priv.PRIVILEDGE_CREATE_ADMIN,
                                  ],
                            });
                          },
                        },
                        {
                          label: "Read Any Admin",
                          type: "checkbox",
                          bsClass: "form-control",
                          checked: priviledges?.some(
                            (val) => val === priv.PRIVILEDGE_GET_ANY_ADMIN
                          ),
                          disabled: !this.props.userPriviledges.some(
                            (val) => val === priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                          ),
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({
                              priviledges: priviledges?.some(
                                (val) => val === priv.PRIVILEDGE_GET_ANY_ADMIN
                              )
                                ? priviledges.filter(
                                    (val) =>
                                      val !== priv.PRIVILEDGE_GET_ANY_ADMIN
                                  )
                                : [
                                    ...priviledges,
                                    priv.PRIVILEDGE_GET_ANY_ADMIN,
                                  ],
                            });
                          },
                        },
                        {
                          label: "Update Admin",
                          type: "checkbox",
                          bsClass: "form-control",
                          checked: priviledges?.some(
                            (val) => val === priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                          ),
                          disabled: !this.props.userPriviledges.some(
                            (val) => val === priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                          ),
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({
                              priviledges: priviledges?.some(
                                (val) =>
                                  val === priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                              )
                                ? priviledges.filter(
                                    (val) =>
                                      val !== priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                                  )
                                : [
                                    ...priviledges,
                                    priv.PRIVILEDGE_UPDATE_ANY_ADMIN,
                                  ],
                            });
                          },
                        },
                        {
                          label: "Reset Password",
                          type: "checkbox",
                          bsClass: "form-control",
                          checked: priviledges?.some(
                            (val) => val === priv.PRIVILEDGE_RESET_PASSWORDS
                          ),
                          disabled: !this.props.userPriviledges.some(
                            (val) => val === priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                          ),
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({
                              priviledges: priviledges?.some(
                                (val) => val === priv.PRIVILEDGE_RESET_PASSWORDS
                              )
                                ? priviledges.filter(
                                    (val) =>
                                      val !== priv.PRIVILEDGE_RESET_PASSWORDS
                                  )
                                : [
                                    ...priviledges,
                                    priv.PRIVILEDGE_RESET_PASSWORDS,
                                  ],
                            });
                          },
                        },
                        {
                          label: "Logout Admin",
                          type: "checkbox",
                          bsClass: "form-control",
                          checked: priviledges?.some(
                            (val) => val === priv.PRIVILEDGE_LOGOUT_ANY_ADMIN
                          ),
                          disabled: !this.props.userPriviledges.some(
                            (val) => val === priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                          ),
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({
                              priviledges: priviledges?.some(
                                (val) =>
                                  val === priv.PRIVILEDGE_LOGOUT_ANY_ADMIN
                              )
                                ? priviledges.filter(
                                    (val) =>
                                      val !== priv.PRIVILEDGE_LOGOUT_ANY_ADMIN
                                  )
                                : [
                                    ...priviledges,
                                    priv.PRIVILEDGE_LOGOUT_ANY_ADMIN,
                                  ],
                            });
                          },
                        },
                        {
                          label: "Delete Admin",
                          type: "checkbox",
                          bsClass: "form-control",
                          checked: priviledges?.some(
                            (val) => val === priv.PRIVILEDGE_DELETE_ANY_ADMIN
                          ),
                          disabled: !this.props.userPriviledges.some(
                            (val) => val === priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                          ),
                          onChange: (e) => {
                            let value = e.currentTarget.value;
                            this.setState({
                              priviledges: priviledges?.some(
                                (val) =>
                                  val === priv.PRIVILEDGE_DELETE_ANY_ADMIN
                              )
                                ? priviledges.filter(
                                    (val) =>
                                      val !== priv.PRIVILEDGE_DELETE_ANY_ADMIN
                                  )
                                : [
                                    ...priviledges,
                                    priv.PRIVILEDGE_DELETE_ANY_ADMIN,
                                  ],
                            });
                          },
                        },
                      ]}
                    />

                    <Button
                      bsStyle="info"
                      pullRight
                      fill
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm("Are you sure you want to proceed")
                        ) {
                          if (mode === this._CREATE_MODE) {
                            this.props.userPriviledges.some(
                              (val) => val === priv.PRIVILEDGE_CREATE_ADMIN
                            ) &&
                              onCreateAdmin({
                                username,
                                password,
                                name,
                                email,
                                phone,
                                address,
                                priviledges,
                                usertype,
                              });
                          } else {
                            this.props.userPriviledges.some(
                              (val) => val === priv.PRIVILEDGE_UPDATE_ANY_ADMIN
                            )
                              ? onSuperProfileUpdate({
                                  id,
                                  username,
                                  name,
                                  phone,
                                  email,
                                  address,
                                  priviledges,
                                  notself,
                                })
                              : onProfileUpdate({
                                  id,
                                  username,
                                  name,
                                  phone,
                                  email,
                                  address,
                                });
                          }
                        }
                      }}
                    >
                      {mode} Profile
                    </Button>

                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>

            <Col md={4}>
              {mode === this._UPDATE_OTHER_MODE ? (
                <Button
                  fill
                  bsStyle="info"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Are you sure you want to reset the password"
                      )
                    ) {
                      onResetPassword(id);
                    }
                  }}
                >
                  Reset Password
                </Button>
              ) : null}

              {mode === this._CREATE_MODE ? (
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Password",
                      type: "password",
                      bsClass: "form-control",
                      placeholder: "Password",
                      value: password,
                      onChange: (e) => {
                        let password = e.currentTarget.value;
                        this.setState({ password });
                      },
                    },
                  ]}
                />
              ) : null}

              {mode === this._UPDATE_SELF_MODE ? (
                <Card
                  title="Change Password"
                  content={
                    <form>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Old password",
                            type: "password",
                            bsClass: "form-control",
                            placeholder: "Old password",
                            value: oldPassword,
                            onChange: (e) => {
                              let oldPassword = e.currentTarget.value;
                              this.setState({ oldPassword });
                            },
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "New Password",
                            type: "password",
                            bsClass: "form-control",
                            placeholder: "Password",
                            value: newPassword,
                            onChange: (e) => {
                              let newPassword = e.currentTarget.value;
                              this.setState({ newPassword });
                            },
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Confirm password",
                            type: "password",
                            bsClass: "form-control",
                            placeholder: "Confirm password",
                            value: confirmPassword,
                            onChange: (e) => {
                              let confirmPassword = e.currentTarget.value;
                              this.setState({ confirmPassword });
                            },
                          },
                        ]}
                      />

                      <Button
                        bsStyle="info"
                        pullRight
                        fill
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          if (
                            window.confirm("Are you sure you want to proceed")
                          ) {
                            onPasswordUpdate({
                              id,
                              oldPassword,
                              newPassword,
                              confirmPassword,
                            });
                            this.clearInputs();
                          }
                        }}
                      >
                        Change Password
                      </Button>
                      <br />
                    </form>
                  }
                />
              ) : null}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);
