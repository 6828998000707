const UserConstants = {
    SET_RESPONSE_DASHBOARD_USER: "SET_RESPONSE_FETCHED_DASHBOARD_USER",
    CLEAR_RESPONSE_DASHBOARD_USER: "CLEAR_RESPONSE_FETCHED_DASHBOARD_USER",
    SET_REQUEST_LOGIN_USER: "SET_REQUEST_LOGIN_USER",
    CLEAR_REQUEST_LOGIN_USER: "CLEAR_REQUEST_LOGIN_USER",
    SET_RESPONSE_LOGIN_USER: "SET_RESPONSE_LOGIN_USER",
    CLEAR_RESPONSE_LOGIN_USER: "CLEAR_RESPONSE_LOGIN_USER",

    SET_REQUEST_CREATE_USER: "SET_REQUEST_CREATE_USER",
    CLEAR_REQUEST_CREATE_USER: "CLEAR_REQUEST_CREATE_USER",
    SET_RESPONSE_CREATE_USER: "SET_RESPONSE_CREATE_USER",
    CLEAR_RESPONSE_CREATE_USER: "CLEAR_RESPONSE_CREATE_USER",

    SET_REQUEST_GET_ALL_USERS: "SET_REQUEST_GET_ALL_USERS",
    CLEAR_REQUEST_GET_ALL_USERS: "CLEAR_REQUEST_GET_ALL_USERS",
    SET_RESPONSE_GET_ALL_USERS: "SET_RESPONSE_GET_ALL_USERS",
    CLEAR_RESPONSE_GET_ALL_USERS: "CLEAR_RESPONSE_GET_ALL_USERS",

    SET_RESPONSE_GET_USER: "SET_RESPONSE_GET_USER",
    CLEAR_RESPONSE_GET_USER: "CLEAR_RESPONSE_GET_USER",

    SET_REQUEST_GET_USER_BY_ID: "SET_REQUEST_GET_USER_BY_ID",
    CLEAR_REQUEST_GET_USER_BY_ID: "CLEAR_REQUEST_GET_USER_BY_ID",
    SET_RESPONSE_GET_USER_BY_ID: "SET_RESPONSE_GET_USER_BY_ID",
    CLEAR_RESPONSE_GET_USER_BY_ID: "CLEAR_RESPONSE_GET_USER_BY_ID",

    SET_REQUEST_GET_USER_BY_CARD_ID: "SET_REQUEST_GET_USER_BY_CARD_ID",
    CLEAR_REQUEST_GET_USER_BY_CARD_ID:
        "CLEAR_REQUEST_GET_USER_BY_CARD_ID",
    SET_RESPONSE_GET_USER_BY_CARD_ID: "SET_RESPONSE_GET_USER_BY_CARD_ID",
    CLEAR_RESPONSE_GET_USER_BY_CARD_ID:
        "CLEAR_RESPONSE_GET_USER_BY_CARD_ID",

    SET_RESPONSE_GET_USER_ACTIVITIES:
        "SET_RESPONSE_GET_USER_ACTIVITIES",
    CLEAR_RESPONSE_GET_USER_ACTIVITIES:
        "CLEAR_RESPONSE_GET_USER_ACTIVITIES",

    SET_REQUEST_GET_USER_BY_ID_ACTIVITIES:
        "SET_REQUEST_GET_USER_BY_ID_ACTIVITIES",
    CLEAR_REQUEST_GET_USER_BY_ID_ACTIVITIES:
        "CLEAR_REQUEST_GET_USER_BY_ID_ACTIVITIES",
    SET_RESPONSE_GET_USER_BY_ID_ACTIVITIES:
        "SET_RESPONSE_GET_USER_BY_ID_ACTIVITIES",
    CLEAR_RESPONSE_GET_USER_BY_ID_ACTIVITIES:
        "CLEAR_RESPONSE_GET_USER_BY_ID_ACTIVITIES",

    SET_REQUEST_UPDATE_USER: "SET_REQUEST_UPDATE_USER",
    CLEAR_REQUEST_UPDATE_USER: "CLEAR_REQUEST_UPDATE_USER",
    SET_RESPONSE_UPDATE_USER: "SET_RESPONSE_UPDATE_USER",
    CLEAR_RESPONSE_UPDATE_USER: "CLEAR_RESPONSE_UPDATE_USER",

    SET_REQUEST_UPDATE_USER_BY_ID: "SET_REQUEST_UPDATE_USER_BY_ID",
    CLEAR_REQUEST_UPDATE_USER_BY_ID: "CLEAR_REQUEST_UPDATE_USER_BY_ID",
    SET_RESPONSE_UPDATE_USER_BY_ID: "SET_RESPONSE_UPDATE_USER_BY_ID",
    CLEAR_RESPONSE_UPDATE_USER_BY_ID: "CLEAR_RESPONSE_UPDATE_USER_BY_ID",

    SET_REQUEST_UPDATE_USER_PASSWORD: "SET_REQUEST_UPDATE_USER_PASSWORD",
    CLEAR_REQUEST_UPDATE_USER_PASSWORD:
        "CLEAR_REQUEST_UPDATE_USER_PASSWORD",
    SET_RESPONSE_UPDATE_USER_PASSWORD:
        "SET_RESPONSE_UPDATE_USER_PASSWORD",
    CLEAR_RESPONSE_UPDATE_USER_PASSWORD:
        "CLEAR_RESPONSE_UPDATE_USER_PASSWORD",

    SET_REQUEST_UPDATE_USER_CARD_ID: "SET_REQUEST_UPDATE_USER_CARD_ID",
    CLEAR_REQUEST_UPDATE_USER_CARD_ID:
        "CLEAR_REQUEST_UPDATE_USER_CARD_ID",
    SET_RESPONSE_UPDATE_USER_CARD_ID:
        "SET_RESPONSE_UPDATE_USER_CARD_ID",
    CLEAR_RESPONSE_UPDATE_USER_CARD_ID:
        "CLEAR_RESPONSE_UPDATE_USER_CARD_ID",

    SET_REQUEST_RESET_USER_PASSWORD: "SET_REQUEST_RESET_USER_PASSWORD",
    CLEAR_REQUEST_RESET_USER_PASSWORD:
        "CLEAR_REQUEST_RESET_USER_PASSWORD",
    SET_RESPONSE_RESET_USER_PASSWORD: "SET_RESPONSE_RESET_USER_PASSWORD",
    CLEAR_RESPONSE_RESET_USER_PASSWORD:
        "CLEAR_RESPONSE_RESET_USER_PASSWORD",

    SET_REQUEST_VERIFY_USER: "SET_REQUEST_VERIFY_USER",
    CLEAR_REQUEST_VERIFY_USER: "CLEAR_REQUEST_VERIFY_USER",
    SET_RESPONSE_VERIFY_USER: "SET_RESPONSE_VERIFY_USER",
    CLEAR_RESPONSE_VERIFY_USER: "CLEAR_RESPONSE_VERIFY_USER",

    SET_RESPONSE_DELETE_USER: "SET_RESPONSE_DELETE_USER",
    CLEAR_RESPONSE_DELETE_USER: "CLEAR_RESPONSE_DELETE_USER",

    SET_REQUEST_DELETE_USER_BY_ID: "SET_REQUEST_DELETE_USER_BY_ID",
    CLEAR_REQUEST_DELETE_USER_BY_ID: "CLEAR_REQUEST_DELETE_USER_BY_ID",
    SET_RESPONSE_DELETE_USER_BY_ID: "SET_RESPONSE_DELETE_USER_BY_ID",
    CLEAR_RESPONSE_DELETE_USER_BY_ID: "CLEAR_RESPONSE_DELETE_USER_BY_ID",

    SET_RESPONSE_LOGOUT_USER: "SET_RESPONSE_LOGOUT_USER",
    CLEAR_RESPONSE_LOGOUT_USER: "CLEAR_RESPONSE_LOGOUT_USER",

    SET_REQUEST_LOGOUT_USER_BY_ID: "SET_REQUEST_LOGOUT_USER_BY_ID",
    CLEAR_REQUEST_LOGOUT_USER_BY_ID: "CLEAR_REQUEST_RESET_USER_PASSWORD",
    SET_RESPONSE_LOGOUT_USER_BY_ID: "SET_RESPONSE_LOGOUT_USER_BY_ID",
    CLEAR_RESPONSE_LOGOUT_USER_BY_ID: "CLEAR_RESPONSE_LOGOUT_USER_BY_ID"
};

export default UserConstants;
